import firebase from "../firebase";
import { doc, getDocs, query, limit, addDoc, updateDoc, setDoc, deleteDoc, arrayRemove, arrayUnion, getDoc, orderBy } from "firebase/firestore";

const db = firebase.collection("events");

// {
//   data,
//     scadenza,
//     titolo,
//     descrizione,
//     immagine,
//     ritrovo,
//     iscritti,
//     costoSoci,
//     costoNonSoci
// }

class EventsService {
  async getAllRaw(maxResults = 15) {
    return await getDocs(query(db, orderBy("data", "desc"), limit(maxResults)));
  }

  async getAll(maxResults = 15) {
    const snapshot = await getDocs(query(db, orderBy("data", "desc"), limit(maxResults)));
    return snapshot.docs.map(doc => doc.data());
  }

  async getEvent(id) {
    const snapshot = await getDoc(doc(db, id));
    return snapshot.data();
  }

  async subscribe(idEvent, element) {
    return await updateDoc(doc(db, idEvent), {
      iscritti: arrayUnion(element)
    });
  }

  async unsubscribe(idEvent, element) {
    return await updateDoc(doc(db, idEvent), { iscritti: arrayRemove(element) });
  }

  async create(center) {
    return await addDoc(db, center);
  }

  async update(id, value) {
    return await setDoc(doc(db, id), value, { merge: true });
  }

  async delete(id) {
    return await deleteDoc(doc(db, id));
  }
}

export default new EventsService();