<template>
  <div class="content-fullheight columns mt-2 mb-6 is-flex is-vcentered">
    <div id="login" class="container">
      <div class="rounded mx-6 has-background-white">
        <div class="column is-10">
          <div class="columns">
            <div
              id="background"
              class="rounded is-hidden-mobile column is-8 mr-3 left"
            ></div>
            <div class="column is-5 m-5 has-text-centered">
              <h1 class="title is-3">
                Torna nel mondo di
                <p class="has-text-grey-light is-inline">Vespa Club</p>
              </h1>
              <div
                v-if="$router.currentRoute.query.redirect == '/events'"
                class="notification rounded is-warning is-light mb-3"
              >
                Esegui l'accesso per poterti iscrivere agli eventi di Vespa Club
                Marostica!
              </div>
              <hr />
              <div
                v-if="ret.message"
                v-bind:class="{
                  'is-danger': !ret.success,
                  'is-success': ret.success,
                }"
                class="notification rounded is-light mb-3"
              >
                {{ ret.message }}
              </div>

              <!-- reset -->
              <form action="#" @submit.prevent="reset" v-if="isAskingReset">
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.email"
                      class="input"
                      type="email"
                      placeholder="Email"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                    <span
                      v-if="!invalidEmail"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>

                <button
                  v-bind:disabled="invalidEmail || waiting"
                  v-bind:class="{ 'is-loading': waiting }"
                  class="button is-block is-dark is-fullwidth"
                >
                  Invia Email di Reset
                </button>
                <br />
                <small
                  ><em class="has-text-centered"
                    >Hai resettato la tua password?
                    <br />
                    <div
                      class="has-text-info"
                      @click.prevent="isAskingReset = false"
                    >
                      <a class="has-text-centered">Accedi &gt;</a>
                    </div></em
                  ></small
                >
                <hr />
                <button class="button" @click.prevent="socialLogin">
                  <span class="icon"> <i class="fab fa-google"></i> </span>
                  <span>Google</span>
                </button>
              </form>

              <!-- login -->
              <form action="#" @submit.prevent="login" v-if="!isAskingReset">
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.email"
                      class="input"
                      type="email"
                      placeholder="Email"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                    <span
                      v-if="!invalidEmail"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>

                <div class="field">
                  <p class="control has-icons-left">
                    <input
                      v-model="form.password"
                      class="input is-size-6"
                      type="password"
                      placeholder="Password"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                  </p>
                </div>

                <small class="is-pulled-right mb-3 mr-2"
                  ><em>
                    <a
                      class="has-text-grey-light"
                      @click.prevent="isAskingReset = true"
                      >Ho dimenticato la password &gt;</a
                    >
                  </em></small
                >

                <button
                  v-bind:disabled="invalidEmail || invalidPassword || waiting"
                  class="button is-block is-dark is-fullwidth"
                  v-bind:class="{ 'is-loading': waiting }"
                >
                  Accedi
                </button>
                <br />
                <small
                  ><em class="has-text-centered"
                    >Non possiedi un account?
                    <br />
                    <router-link class="has-text-info" to="/register"
                      ><a class="has-text-centered"
                        >Registrati &gt;</a
                      ></router-link
                    ></em
                  ></small
                >
                <hr />
                <button class="button" @click.prevent="socialLogin">
                  <span class="icon"> <i class="fab fa-google"></i> </span>
                  <span>Google</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import A from "../store/action-types.js";

export default {
  name: "Login",
  metaInfo: {
    title: "Accedi",
  },
  data() {
    return {
      isAskingReset: false,

      form: {
        email: "",
        password: "",
      },
      invalidEmail: true,
      invalidPassword: true,
      waiting: false,
      ret: {
        success: false,
        message: "",
      },
    };
  },
  created() {
    // ha appena completato la registrazione ed ho fatto qui un redirect
    if (this.$route.query.register) {
      this.ret = {
        message: "Registrazione avvenuta con successo, accedi per continuare",
        success: true,
      };
    }
  },
  watch: {
    "form.email"(value) {
      // binding this to the data value in the email input
      // this.email = value;
      this.validateEmail(value);
    },
    "form.password"(value) {
      // binding this to the data value in the password input
      // this.password = value;
      this.validatePassword(value);
    },
    isAskingReset() {
      this.ret = {};
    },
  },
  methods: {
    async reset() {
      this.ret = {};
      this.waiting = true;
      this.ret = await this.$store.dispatch(A.RESET_PASSWORD, {
        email: this.form.email,
      });
      this.waiting = false;
    },
    async login() {
      this.ret = {};
      this.waiting = true;
      this.ret = await this.$store.dispatch(A.LOGIN, {
        email: this.form.email,
        password: this.form.password,
      });
      this.waiting = false;
    },
    async socialLogin() {
      this.ret = await this.$store.dispatch(A.LOGIN_GOOGLE);
    },
    validateEmail(value) {
      if (this.$store.getters.regexEmail.test(value)) {
        this.invalidEmail = false;
      } else {
        this.invalidEmail = true;
      }
    },
    validatePassword(value) {
      if (String(value).trim() != "") {
        this.invalidPassword = false;
      } else {
        this.invalidPassword = true;
      }
    },
  },
};
</script>

<style scoped>
#login {
  max-width: 1100px;
}

#background {
  background-image: url("../assets/background_2.jpg");
  mask-image: linear-gradient(
    to right,
    rgb(160, 122, 122) 50%,
    transparent 100%
  );

  background-size: cover;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  /* background-position: center; */
}
</style>