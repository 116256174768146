<template>
  <tr class="table-row-event">
    <td>
      <textarea
        v-model="event.titolo"
        v-bind:disabled="waiting"
        class="input textarea"
        type="text"
        v-on:input="edited = true"
        maxlength="80"
        placeholder="Titolo"
      />
    </td>
    <td>
      <input
        v-model="event.data"
        v-bind:disabled="waiting"
        class="input"
        type="datetime-local"
        v-on:input="edited = true"
      />
    </td>
    <td>
      <small>
        {{ countIscritti(event.iscritti) }} iscritti
        <span v-if="event.pasto">
          - {{ countPasti(event.iscritti) }} pasti
        </span>
      </small>
      <hr class="my-2" />
      <div
        v-for="(iscritto, index) in event.iscritti"
        v-bind:key="index"
        class="tag is-info mx-1 mt-1"
      >
        {{
          iscritto.name +
          (iscritto.quantity > 1 ? " (" + iscritto.quantity + ")" : "")
        }}
        <span
          class="ml-1"
          v-bind:class="{
            'has-text-warning':
              event.pasto && iscritto.pasti && iscritto.pasti > 0,
          }"
          >{{ event.pasto ? countSoloGiro(iscritto) : "" }}
        </span>
        <button
          v-bind:disabled="waitingRemoveSubscription"
          @click="removeIscritto(event, index)"
          class="delete is-small"
        ></button>
      </div>
    </td>
    <td>
      <textarea
        v-model="event.descrizione"
        v-bind:disabled="waiting"
        class="input textarea"
        type="text"
        v-on:input="edited = true"
        maxlength="300"
        placeholder="Descrizione"
      />
    </td>
    <td>
      <textarea
        v-model="event.ritrovo"
        v-bind:disabled="waiting"
        class="input textarea"
        type="text"
        v-on:input="edited = true"
        maxlength="100"
        placeholder="Ritrovo"
      />
    </td>
    <td>
      <input
        v-model="event.scadenza"
        v-bind:disabled="waiting"
        class="input"
        type="datetime-local"
        v-on:input="edited = true"
      />
    </td>
    <td>
      <input
        v-model="event.costoSoci"
        v-bind:disabled="waiting"
        class="input"
        type="text"
        v-on:input="edited = true"
        maxlength="15"
        placeholder="Costo soci"
      />
    </td>
    <td>
      <input
        v-model="event.costoNonSoci"
        v-bind:disabled="waiting"
        class="input"
        type="text"
        v-on:input="edited = true"
        maxlength="15"
        placeholder="Costo non soci"
      />
    </td>
    <td class="control has-text-right">
      <div class="is-small is-info">
        <div class="field">
          <input
            v-bind:disabled="waiting"
            v-bind:id="'switchPasto' + event.id"
            type="checkbox"
            v-on:change="edited = true"
            v-bind:name="'switchPasto' + event.id"
            class="switch is-info"
            v-model="event.pasto"
          />
          <label v-bind:for="'switchPasto' + event.id"></label>
        </div>
      </div>
    </td>
    <td class="control has-text-right">
      <div class="is-small is-info">
        <div class="field">
          <input
            v-bind:disabled="waiting"
            v-bind:id="'switchVisibleToNonSoci' + event.id"
            type="checkbox"
            v-on:change="edited = true"
            v-bind:name="'switchVisibleToNonSoci' + event.id"
            class="switch is-info"
            v-model="event.visibleToNonSoci"
          />
          <label v-bind:for="'switchVisibleToNonSoci' + event.id"></label>
        </div>
      </div>
    </td>
    <td class="control">
      <div class="select is-small is-dark mb-1">
        <!-- prima immagine -->
        <select
          v-bind:disabled="waiting"
          @change="changeImage($event, 0)"
          v-on:change="edited = true"
          class=""
        >
          <option key="unset0" value="" v-bind:selected="!event.immagine[0]">
            -
          </option>
          <option
            v-for="(image, index) in images"
            v-bind:key="index"
            v-bind:selected="image.fullPath == event.immagine[0]"
            v-bind:value="image.fullPath"
          >
            {{ image.name }}
          </option>
        </select>
      </div>
      <div class="select is-small is-dark my-1">
        <!-- seconda immagine -->
        <select
          v-bind:disabled="waiting"
          @change="changeImage($event, 1)"
          v-on:change="edited = true"
          class=""
        >
          <option key="unset1" value="" v-bind:selected="!event.immagine[1]">
            -
          </option>
          <option
            v-for="(image, index) in images"
            v-bind:key="index"
            v-bind:selected="image.fullPath == event.immagine[1]"
            v-bind:value="image.fullPath"
          >
            {{ image.name }}
          </option>
        </select>
      </div>
      <div class="select is-small is-dark my-1">
        <!-- terza immagine -->
        <select
          v-bind:disabled="waiting"
          @change="changeImage($event, 2)"
          v-on:change="edited = true"
          class=""
        >
          <option key="unset2" value="" v-bind:selected="!event.immagine[2]">
            -
          </option>
          <option
            v-for="(image, index) in images"
            v-bind:key="index"
            v-bind:selected="image.fullPath == event.immagine[2]"
            v-bind:value="image.fullPath"
          >
            {{ image.name }}
          </option>
        </select>
      </div>
      <div class="select is-small is-dark mt-1">
        <!-- quarta immagine -->
        <select
          v-bind:disabled="waiting"
          @change="changeImage($event, 3)"
          v-on:change="edited = true"
          class=""
        >
          <option key="unset3" value="" v-bind:selected="!event.immagine[3]">
            -
          </option>
          <option
            v-for="(image, index) in images"
            v-bind:key="index"
            v-bind:selected="image.fullPath == event.immagine[3]"
            v-bind:value="image.fullPath"
          >
            {{ image.name }}
          </option>
        </select>
      </div>
    </td>
    <td>
      <button
        class="button is-info mr-2 my-1"
        v-bind:disabled="waiting || !edited"
        v-bind:class="{
          'is-loading': waiting,
        }"
        @click.prevent="save"
      >
        Salva modifiche
      </button>
      <button
        class="button is-danger mr-2 my-1"
        v-bind:disabled="waiting"
        v-bind:class="{
          'is-loading': waiting,
        }"
        @click.prevent="remove"
      >
        Elimina
      </button>
      <button
        class="button is-primary mr-2 my-1"
        @click.prevent="
          $emit('openModal', {
            title: titleNotification(event),
            description: event.descrizione,
            clickAction: 'https://vespaclubmarostica.it/events',
          })
        "
      >
        Notifica
      </button>
      <div v-if="error" class="has-text-danger has-text-centered mb-3">
        {{ error }}
      </div>
    </td>
  </tr>
</template>

<script>
// import firebase from "../firebase";
import Events from "../services/events";

export default {
  name: "TableRowEvent",
  data() {
    return {
      error: null,
      waiting: false,
      edited: false,

      selectedImages: ["", "", "", ""],

      waitingRemoveSubscription: false,
    };
  },
  computed: {
    titleNotification() {
      return (event) => {
        return event.titolo + " - " + new Date(event.data).toLocaleDateString();
      };
    },
    countIscritti() {
      return (iscritti) =>
        iscritti.reduce((prev, current) => prev + current.quantity, 0);
    },
    countPasti() {
      return (iscritti) =>
        iscritti.reduce(
          (prev, current) => prev + (current.pasti ? current.pasti : 0),
          0
        );
    },
    countSoloGiro() {
      return (iscritto) => {
        return iscritto.quantity - iscritto.pasti > 0
          ? "[ con " +
              (iscritto.quantity > 1
                ? iscritto.quantity > iscritto.pasti
                  ? iscritto.quantity - iscritto.pasti + " "
                  : ""
                : "") +
              "solo giro ]"
          : "";
      };
    },
  },
  created() {
    this.selectedImages = this.event.immagine;
  },
  props: {
    event: {
      required: true,
      type: Object,
    },
    images: {
      required: true,
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    changeImage(event, index) {
      this.selectedImages.splice(index, 1, event.target.value);
    },
    async save() {
      this.waiting = true;
      this.error = "";
      this.event.immagine.splice(
        0,
        this.event.immagine.length,
        ...this.selectedImages
      );

      await Events.update(this.event.id, this.event)
        .then(() => {
          this.edited = false;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    async removeIscritto(event, index) {
      if (
        confirm("Sei sicuro di voler rimuovere questo iscritto dall'evento?") ==
        true
      ) {
        this.waitingRemoveSubscription = true;
        this.error = "";

        Events.unsubscribe(event.id, event.iscritti[index])
          .then(() => {
            event.iscritti.splice(index, 1);
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.waitingRemoveSubscription = false;
          });
      }
    },
    async remove() {
      if (
        confirm(
          "Sei sicuro di voler eliminare questo evento?\nLe iscrizioni ad esso verranno perse"
        ) == true
      ) {
        this.waiting = true;
        this.error = "";

        await Events.delete(this.event.id)
          .then(() => {
            this.$emit("event-deleted", this.event);
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.waiting = false;
          });
      }
    },
  },
};
</script>
