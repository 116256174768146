<template>
  <div class="content-fullheight about">
    <!--Hero-->
    <section id="hero" class="hero py-6">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-vcentered container">
            <div class="column mx-6 mb-4">
              <h1 class="title">
                Benvenuto nel
                <p class="has-text-grey-light is-inline">
                  Vespa Club Marostica
                </p>
              </h1>
              <p class="has-text-centered-mobile">
                Il giorno 17 dicembre 2014 in Marostica, 4 amici, ovvero Andrea
                Crestani, Stefano Presa, Dario Costa e Massimo Tasca, accomunati
                da una vera passione per la Vespa hanno dato vita al VESPA CLUB
                MAROSTICA.
              </p>

              <hr class="has-background-grey-light" />

              <p class="subtitle is-3">IL DIRETTIVO</p>
              <p class="has-text-centered mb-2">
                <!-- <i
                  class="fa fa-check has-text-success mr-2"
                  aria-hidden="true"
                ></i> -->
                <strong>PRESIDENTE:</strong> Stefano Presa
              </p>
              <p class="has-text-centered mb-2">
                <!-- <i
                  class="fa fa-check has-text-success mr-2"
                  aria-hidden="true"
                ></i> -->
                <strong>VICEPRESIDENTE:</strong> Eros Seganfreddo
              </p>
              <p class="has-text-centered mb-2">
                <!-- <i
                  class="fa fa-check has-text-success mr-2"
                  aria-hidden="true"
                ></i> -->
                <strong>SEGRETARIO:</strong> Sandro Dal Maso
              </p>
              <p class="has-text-centered mb-2">
                <!-- <i
                  class="fa fa-check has-text-success mr-2"
                  aria-hidden="true"
                ></i> -->
                <strong>CONSIGLIERE:</strong> Diego Viero
              </p>
              <!-- <router-link to="/events"
                ><a class="button is-dark is-outlined"
                  ><strong>
                    Controlla gli eventi<span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i> </span
                  ></strong> </a
              ></router-link> -->
            </div>

            <div class="column mx-3 py-5">
              <img
                alt="il direttivo"
                data-aos="fade-left"
                class="
                  cover-image
                  image
                  aspect-ratio
                  rounded
                  has-border-light has-shadow-light
                "
                src="../assets/about.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <Contacts />

    <div class="has-background-grey-dark is-relative">
      <div
        id="responsive_headline_about"
        class="background-text has-text-grey is-uppercase is-italic"
      >
        V e s p a
      </div>
      <div class="container my-3">
        <div class="columns is-vcentered container py-6">
          <div class="column mx-5 py-5">
            <img
              alt="vespe in fila"
              data-aos="fade-right"
              class="
                cover-image
                image
                aspect-ratio
                rounded
                has-border-light has-shadow-light
              "
              src="../assets/about2.jpg"
            />
          </div>
          <div class="column mx-6">
            <h1 class="title has-text-light is-uppercase">
              ISCRIVITI AL PROSSIMO EVENTO
            </h1>
            <p class="has-text-light mb-5">
              Iscriviti al prossimo evento in programma organizzato dal Club.
              Leggi qui tutte le informazioni di cui hai bisogno.
            </p>
            <router-link to="/events"
              ><a class="button is-light"
                ><strong>
                  Iscriviti<span class="icon is-small ml-1">
                    <i class="fas fa-chevron-right"></i> </span
                ></strong> </a
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import A from "../store/action-types.js";
import Contacts from "../components/Contacts.vue";

export default {
  name: "About",
  metaInfo: {
    title: "Chi siamo",
  },
  data() {
    return {
      form: {
        email: "",
      },
      invalidEmail: true,
      waiting: false,
      ret: {
        success: false,
        message: "",
      },
    };
  },
  components: {
    Contacts,
  },
  watch: {
    "form.email"(value) {
      // binding this to the data value in the email input
      // this.email = value;
      this.validateEmail(value);
    },
  },
  methods: {
    async subscribeEmail() {
      this.ret = {};
      this.waiting = true;
      this.ret = await this.$store.dispatch(A.SUBSCRIBE_EMAIL, {
        email: this.form.email,
      });
      this.waiting = false;
      this.form.email = "";
    },
    validateEmail(value) {
      if (this.$store.getters.regexEmail.test(value)) {
        this.invalidEmail = false;
      } else {
        this.invalidEmail = true;
      }
    },
  },
  mounted() {
    try {
      if (window.fitText != undefined) {
        window.fitText(
          document.getElementById("responsive_headline_about"),
          0.4
        );
        // window.fitText(
        //   document.getElementById("responsive_headline_thiene"),
        //   0.4
        // );
        // window.fitText(
        //   document.getElementById("responsive_headline_vicenza"),
        //   0.4
        // );
      }
    } catch (e) {
      console.warn(e);
    }
  },
};
</script>

<style scoped></style>
