<template>
  <div class="clothing">
    <section class="my-5 container content-fullheight">
      <div v-if="error" class="has-text-danger has-text-centered mb-3">
        {{ error }}
      </div>

      <div class="column">
        <button
          v-if="waiting"
          disabled
          class="button is-rounded is-info is-loading"
        ></button>
      </div>

      <div v-if="clothings.length == 0 && !waiting">Nessun abbigliamento disponibile</div>
      <div class="px-6 columns is-multiline">
        <div
          v-for="(currentClothe, index) in clothings"
          v-bind:key="index"
          class="column is-3-desktop is-4-tablet"
        >
          <div class="card">
            <div class="card-image aspect-ratio px-2 pt-2">
              <expandable-image
                alt=""
                class="rounded has-shadow-light"
                v-bind:src="currentClothe.url"
              />
            </div>
            <div class="card-content">
              {{
                currentClothe.prezzo != null && currentClothe.prezzo != ""
                  ? currentClothe.prezzo.toString().includes("€")
                    ? currentClothe.prezzo
                    : currentClothe.prezzo + " €"
                  : ""
              }}
              <p class="title is-3">{{ currentClothe.titolo }}</p>

              <div class="content">
                <p
                  v-bind:class="{
                    'has-text-danger': !currentClothe.available,
                    'has-text-success': currentClothe.available,
                  }"
                >
                  {{ currentClothe.available ? "Disponibile" : "Non disponibile" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Contacts />
  </div>
</template>

<script>
import Contacts from "../components/Contacts.vue";

import Clothing from "../services/clothing";
import Photos from "../services/photos";

export default {
  name: "Clothing",
  metaInfo: {
    title: "Abbigliamento",
  },
  components: {
    Contacts,
  },
  data() {
    return {
      clothings: [],
      waiting: null,
      error: null,
    };
  },
  async created() {
    // clothing
    this.waiting = true;

    Clothing.getAllRaw()
      .then(async (snap) => {
        snap.forEach((doc) => {
          let clothe = doc.data();
          // utility properties
          clothe.id = doc.id;
          clothe.url = undefined;
          this.clothings.push(clothe);
          // async variables
          this.getDownloadUrl(clothe.immagine)
            .then((value) => {
              this.clothings.find((n) => n.id == clothe.id).url = value;
            })
            .catch(() => {});
        });
      })
      .catch((reason) => {
        this.error = reason;
      })
      .finally(() => {
        this.waiting = false;
      });
  },
  methods: {
    async getDownloadUrl(fileName) {
      return await Photos.getDownloadURLClothing(fileName);
    },
  },
};
</script>

<style scoped></style>
