var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"table-row-news"},[_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.news.titolo),expression:"news.titolo"}],staticClass:"input textarea",attrs:{"disabled":_vm.waiting,"type":"text","maxlength":"100","placeholder":"Titolo"},domProps:{"value":(_vm.news.titolo)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.news, "titolo", $event.target.value)},function($event){_vm.edited = true}]}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.news.data),expression:"news.data"}],staticClass:"input",attrs:{"disabled":_vm.waiting,"type":"datetime-local"},domProps:{"value":(_vm.news.data)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.news, "data", $event.target.value)},function($event){_vm.edited = true}]}})]),_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.news.descrizione),expression:"news.descrizione"}],staticClass:"input textarea",attrs:{"disabled":_vm.waiting,"type":"text","maxlength":"2000","placeholder":"Descrizione"},domProps:{"value":(_vm.news.descrizione)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.news, "descrizione", $event.target.value)},function($event){_vm.edited = true}]}})]),_c('td',{staticClass:"control"},[_c('div',{staticClass:"select is-small is-dark"},[_c('select',{attrs:{"disabled":_vm.waiting},on:{"change":[function($event){return _vm.changeImage($event)},function($event){_vm.edited = true}]}},_vm._l((_vm.images),function(image,index){return _c('option',{key:index,domProps:{"selected":image.fullPath == _vm.news.immagine,"value":image.fullPath}},[_vm._v(" "+_vm._s(image.name)+" ")])}),0)])]),_c('td',[_c('button',{staticClass:"button is-info mr-2 my-1",class:{
        'is-loading': _vm.waiting,
      },attrs:{"disabled":_vm.waiting || !_vm.edited},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Salva modifiche ")]),_c('button',{staticClass:"button is-danger mr-2 my-1",class:{
        'is-loading': _vm.waiting,
      },attrs:{"disabled":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.remove.apply(null, arguments)}}},[_vm._v(" Elimina ")]),_c('button',{staticClass:"button is-primary mr-2 my-1",on:{"click":function($event){$event.preventDefault();_vm.$emit('openModal', {
          title: _vm.titleNotification(_vm.news),
          description: _vm.news.descrizione,
          clickAction: 'https://vespaclubmarostica.it/news'
        })}}},[_vm._v(" Notifica ")]),(_vm.error)?_c('div',{staticClass:"has-text-danger has-text-centered mb-3"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }