<template>
  <div class="content-fullheight">
    <section class="blog-posts mt-5">
      <div v-if="error" class="has-text-danger has-text-centered mb-3">
        {{ error }}
      </div>

      <div class="column">
        <button
          v-if="waiting"
          disabled
          class="button is-rounded is-info is-loading"
        ></button>
      </div>

      <div v-if="news.length == 0 && !waiting">Nessuna notizia presente</div>
      <div v-for="(currentNews, index) in news" v-bind:key="index" class="container my-3">
        <div class="is-10 is-offset-1">
          <div class="columns px-3 featured-post is-multiline">
            <div class="column is-12 post">
              <article class="featured">
                <div class="column is-9-desktop container post-img">
                  <img
                    alt=""
                    data-aos="zoom-in"
                    class="aspect-ratio rounded has-shadow-light cover-image"
                    v-bind:src="currentNews.url"
                  />
                </div>
              </article>
              <div class="featured-content va">
                <div>
                  <h3 class="heading post-category">
                    {{ getDateString(currentNews.data) }}
                  </h3>
                  <h1 class="title post-title has-text-info">
                    {{ currentNews.titolo }}
                  </h1>
                  <p class="column post-excerpt is-7-desktop container">
                    {{ currentNews.descrizione }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import News from "../services/news";
import Photos from "../services/photos";

export default {
  name: "News",
  metaInfo: {
    title: "Notizie",
  },
  data() {
    return {
      news: [],
      waiting: null,
      error: null,
    };
  },
  async created() {
    // news
    this.waiting = true;

    News.getAllRaw()
      .then(async (snap) => {
        snap.forEach((doc) => {
          let news = doc.data();
          // utility properties
          news.id = doc.id;
          news.url = undefined;
          this.news.push(news);
          // async variables
          this.getDownloadUrl(news.immagine)
            .then((value) => {
              this.news.find((n) => n.id == news.id).url = value;
            })
            .catch(() => {});
        });
      })
      .catch((reason) => {
        this.error = reason;
      })
      .finally(() => {
        this.waiting = false;
      });
  },
  methods: {
    async getDownloadUrl(fileName) {
      return await Photos.getDownloadURL(fileName);
    },
    getDateString(date) {
      if (date) {
        let d = new Date(date);
        return d.toLocaleDateString("it-IT", {
          year: "numeric",
          weekday: "long",
          month: "long",
          day: "numeric",
        });
      } else return "";
    },
  },
};
</script>

<style scoped></style>
