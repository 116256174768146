<template>
  <div class="content-fullheight columns mt-2 mb-6 is-flex is-vcentered">
    <div class="container">
      <div class="column mx-2 has-background-white">
        <div class="columns is-vcentered">
          <div class="column">
            <button
              v-if="waiting"
              disabled
              class="button is-rounded is-info is-loading"
            ></button>
          </div>

          <div v-if="!waiting" class="column is-4 m-5 has-text-centered">
            <h1 class="title">
              Il tuo profilo
              <p class="has-text-grey-light is-inline">Vespa Club</p>
            </h1>
            <div class="subtitle mt-1 block">
              Visualizza o modifica le tue informazioni
            </div>
            <div
              v-if="$store.getters.user.claims.hasAccess === false"
              class="notification is-danger rounded is-light mb-3"
            >
              Il tuo profilo non è ancora stato aggiunto ai soci.
              <br />
              Per abilitare le prenotazioni agli eventi, contattaci!
            </div>
            <div
              v-else-if="$store.getters.user.claims.email_verified === false"
            >
              <div class="notification is-warning rounded is-light mb-3">
                La tua email non è ancora stata verificata
              </div>
            </div>

            <hr />

            <div
              v-if="retUpdateProfile.message"
              v-bind:class="{
                'is-danger': !retUpdateProfile.success,
                'is-success': retUpdateProfile.success,
              }"
              class="notification rounded is-light mb-3"
            >
              {{ retUpdateProfile.message }}
            </div>

            <div
              v-if="retUpdatePassword.message"
              v-bind:class="{
                'is-danger': !retUpdatePassword.success,
                'is-success': retUpdatePassword.success,
              }"
              class="notification rounded is-light mb-3"
            >
              {{ retUpdatePassword.message }}
            </div>

            <form action="#" class="">
              <div class="field">
                <p class="control is-expanded has-icons-left has-icons-right">
                  <input
                    v-model="form.displayName"
                    class="input"
                    type="text"
                    maxlength="50"
                    placeholder="Nome Cognome"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-signature"></i>
                  </span>
                  <span
                    v-if="
                      !invalidName &&
                      $store.getters.username != form.displayName
                    "
                    class="icon has-text-success is-right"
                  >
                    <i class="fas fa-check"></i>
                  </span>
                </p>
              </div>

              <div
                v-bind:class="{
                  'has-addons': !$store.getters.user.emailVerified,
                }"
                class="field"
              >
                <p class="control is-expanded has-icons-left">
                  <input
                    disabled
                    v-model="form.email"
                    class="input"
                    type="email"
                    placeholder="Email"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <!-- <span
                      v-if="!invalidEmail"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span> -->
                </p>
                <div v-if="!$store.getters.user.emailVerified" class="control">
                  <button
                    @click.prevent="verifyEmail"
                    v-bind:disabled="waitingVerify || retVerify.success"
                    class="button is-block"
                    v-bind:class="{
                      'is-loading': waitingVerify,
                      'is-dark': retVerify.success,
                      'is-warning': !retVerify.success,
                    }"
                  >
                    {{ retVerify.success ? "Inviata!" : "Verifica" }}
                  </button>
                </div>
              </div>

              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <input
                    v-model="form.phone"
                    class="input"
                    type="tel"
                    @change="changedInfo = true"
                    placeholder="Numero di telefono"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-mobile-alt"></i>
                  </span>
                </p>
              </div>

              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <input
                    v-model="form.codiceFiscale"
                    class="input"
                    type="text"
                    @change="changedInfo = true"
                    maxlength="50"
                    placeholder="Codice Fiscale"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-id-card"></i>
                  </span>
                </p>
              </div>

              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <input
                    v-model="form.address"
                    class="input"
                    type="text"
                    @change="changedInfo = true"
                    maxlength="70"
                    placeholder="Indirizzo"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-search-location"></i>
                  </span>
                </p>
              </div>

              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <input
                    v-model="form.dataNascita"
                    class="input"
                    type="date"
                    @change="changedInfo = true"
                    placeholder="Data"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-birthday-cake"></i>
                  </span>
                </p>
              </div>

              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <input
                    v-model="form.luogoNascita"
                    class="input"
                    type="text"
                    @change="changedInfo = true"
                    maxlength="40"
                    placeholder="Luogo di Nascita"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-street-view"></i>
                  </span>
                </p>
              </div>

              <div class="mb-3" v-if="showUpdatePassword && canUpdatePassword">
                <div class="field pb-4">
                  <p class="control has-icons-left">
                    <input
                      v-model="form.oldPassword"
                      class="input"
                      type="password"
                      placeholder="Password corrente"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-unlock"></i>
                    </span>
                  </p>
                </div>

                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.password"
                      class="input"
                      type="password"
                      placeholder="Nuova Password"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                    <span
                      v-if="!invalidPassword"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>

                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.confirmPassword"
                      class="input"
                      type="password"
                      placeholder="Conferma Password"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                    <span
                      v-if="!invalidConfirmPassword"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>

                <div
                  class="
                    container
                    is-size-7
                    has-text-left has-text-grey-light
                    px-5
                    pt-3
                    pb-2
                  "
                >
                  Le password devono:
                  <ul class="">
                    <li v-bind:class="{ 'has-text-danger': invalidPassword }">
                      - avere lunghezza di 8 o più caratteri
                    </li>
                    <li v-bind:class="{ 'has-text-danger': invalidPassword }">
                      - contenere almeno una lettera
                    </li>
                    <li v-bind:class="{ 'has-text-danger': invalidPassword }">
                      - contenere almeno un numero
                    </li>
                    <li
                      v-bind:class="{
                        'has-text-danger': invalidConfirmPassword,
                      }"
                    >
                      - corrispondere
                    </li>
                  </ul>
                </div>
              </div>

              <div v-if="canUpdatePassword">
                <button
                  @click.prevent="showUpdatePassword = !showUpdatePassword"
                  class="button is-dark is-fullwidth is-small has-icons-right"
                  type="button"
                >
                  {{ showUpdatePassword ? "Chiudi" : "Modifica password" }}
                  <span class="icon is-right ml-1">
                    <i
                      v-bind:class="{
                        'fa-angle-down': !showUpdatePassword,
                        'fa-angle-up': showUpdatePassword,
                      }"
                      class="fas"
                    ></i>
                  </span>
                </button>
              </div>
              <small v-else>
                <em class="has-text-grey-light mx-3"
                  >Non è possibile modificare la password per i profili che
                  accedono con Google</em
                >
              </small>

              <div class="field level ml-1 mt-4">
                <input
                  v-bind:disabled="waitingEmail"
                  class="is-checkradio is-info"
                  id="emailPreferenceCheckbox"
                  type="checkbox"
                  name="emailPreferenceCheckbox"
                  v-bind:checked="form.emailSubscription"
                  @change="changeEmailPreference"
                />
                <label for="emailPreferenceCheckbox"
                  >Tienimi aggiornato sulle novità via Email</label
                >
              </div>

              <div class="field level ml-1 mt-4">
                <input
                  v-bind:disabled="waitingNotifications"
                  class="is-checkradio is-info"
                  id="notifPreferenceCheckbox"
                  type="checkbox"
                  name="emailPreferenceCheckbox"
                  v-bind:checked="form.noticationsEnabled"
                  @click.prevent="toggleNotifications"
                />
                <label for="notifPreferenceCheckbox"
                  >Notifiche su nuovi eventi</label
                >
              </div>
              <div class="is-size-7 has-text-grey-light has-text-left">
                Su iPhone o iPad, funziona solo su iOS 16.4+ con Safari. Per abilitare le notifiche è necessario:
                <br />
                - Abilitare le notifiche da 'Impostazioni &gt; Safari &gt; Avanzate &gt; Notifications' o 'Push API'
                <br />
                - aggiungere il sito alla home screen con 'Condividi &gt;
                Aggiungi alla Schermata Home'
                <br />
                - Premere sulla casella per abilitare notifiche premendo
                'Consenti'
                <br />
                - Chiudere e riprovare in caso non funzioni
                <br />
              </div>

              <hr />

              <button
                @click.prevent="updateProfileAndPassword"
                v-bind:disabled="
                  (showUpdatePassword
                    ? !form.oldPassword ||
                      invalidPassword ||
                      invalidConfirmPassword
                    : $store.getters.user.displayName == form.displayName &&
                      !changedInfo) ||
                  invalidName ||
                  waitingUpdate
                "
                type="submit"
                class="button is-block is-info is-pulled-right mb-3"
                v-bind:class="{ 'is-loading': waitingUpdate }"
              >
                Salva modifiche
              </button>
            </form>
          </div>

          <div class="is-hidden-mobile column is-6">
            <img alt="logo" src="../assets/logo_icon_color_dark.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import A from "../store/action-types.js";
import Emails from "../services/emails";
import Users from "../services/users";
import firebase from "../firebase";

export default {
  name: "Profile",
  metaInfo: {
    title: "Profilo",
  },
  data() {
    return {
      showUpdatePassword: false,
      canUpdatePassword: false, // per chi logga con Google
      form: {
        displayName: "",
        email: "",
        phone: "",
        codiceFiscale: "",
        luogoNascita: "",
        dataNascita: "",
        address: "",
        oldPassword: "",
        password: "",
        confirmPassword: "",
        emailSubscription: false,
        noticationsEnabled: false,
      },
      invalidName: false,
      invalidEmail: false,
      invalidPassword: true,
      invalidConfirmPassword: true,

      changedInfo: false,

      waitingEmail: null,
      retEmail: {
        success: false,
        message: "",
      },
      waitingNotifications: null,
      waitingVerify: null,
      retVerify: {
        success: false,
        message: "",
      },
      waitingUpdate: null,
      retUpdateProfile: {
        success: false,
        message: "",
      },
      retUpdatePassword: {
        success: false,
        message: "",
      },
      waiting: null,
      ret: {
        success: false,
        message: "",
      },
    };
  },
  watch: {
    "form.displayName"(value) {
      // binding this to the data value in the email input
      // this.email = value;
      this.validateName(value);
    },
    "form.password"(value) {
      // binding this to the data value in the email input
      this.validatePassword(value);
      this.validateConfirmPassword(this.form.confirmPassword);
    },
    "form.confirmPassword"(value) {
      // binding this to the data value in the email input
      this.validateConfirmPassword(value);
    },
  },
  async created() {
    // firebase user
    this.waiting = true;
    await this.$store.dispatch(A.RELOAD_USER).finally(async () => {
      this.form.email = this.$store.getters.user.email;
      this.form.displayName = this.$store.getters.user.displayName;
      this.canUpdatePassword = this.$store.getters.authProvider == "password";
      Users.get(this.$store.getters.user.uid)
        .then((firestoreUser) => {
          this.form.phone = firestoreUser.phone;
          this.form.dataNascita = firestoreUser.dataNascita;
          this.form.luogoNascita = firestoreUser.luogoNascita;
          this.form.address = firestoreUser.address;
          this.form.codiceFiscale = firestoreUser.codiceFiscale;
        })
        .finally(() => {
          this.waiting = false;
        });
    });
    // emailPreference
    this.waitingEmail = true;
    await Emails.isSubscribed(this.$store.getters.user.email)
      .then((isSubbed) => {
        this.form.emailSubscription = isSubbed;
      })
      .finally(() => {
        this.waitingEmail = false;
      });
    // subscribed to notifications?
    this.form.noticationsEnabled =
      localStorage.getItem(
        this.$store.getters.localStorageNotificationsSubscriptionKey
      ) == "true";
  },
  methods: {
    async askNotificationPermission() {
      // Let's check if the browser supports notifications
      return await new Promise((resolve) => {
        if (!("Notification" in window)) {
          console.log("This browser does not support notifications.");
          this.$notify({
            group: "global",
            title: "Errore",
            type: "error",
            text: "Questo browser non supporta le notifiche.",
            duration: 5000,
          });

          resolve(false);
        } else {
          try {
            Notification.requestPermission().then((permission) =>
              this.handlePermission(permission).then((success) => {
                resolve(success);
              })
            );
          } catch (e) {
            console.log("Notifications not supported.");
            this.$notify({
              group: "global",
              title: "Errore",
              type: "error",
              text: "Questo browser non supporta le notifiche.",
              duration: 5000,
            });

            resolve(false);
          }
        }
      });
    },
    async handlePermission(permission) {
      // set the button to shown or hidden, depending on what the user answers
      // this.form.noticationsEnabled = permission === "granted";
      // await this.subscribeUserToPush();
      return new Promise((resolve) => {
        if (permission === "granted") {
          this.subscribeUserToPush().then((success) => {
            if (success) resolve(this.subscribeToTopic("general"));
            else resolve(false);
          });
        } else {
          console.log("Notifications not granted.");
          this.$notify({
            group: "global",
            title: "Errore",
            type: "error",
            text: "Permesso alle notifiche negato.",
            duration: 5000,
          });

          resolve(false);
        }
      });
    },
    async toggleNotifications() {
      this.waitingNotifications = true;

      let success = false;
      if (this.form.noticationsEnabled) {
        success = await this.disableNotifications();
      } else {
        success = await this.askNotificationPermission();
      }
      if (success) {
        this.form.noticationsEnabled = !this.form.noticationsEnabled;
        this.$notify({
          group: "global",
          title:
            "Notifiche " +
            (!this.form.noticationsEnabled ? "disattivate" : "attivate"),
          type: "info",
          duration: 5000,
        });
      } else {
        // const isSafari = /^((?!chrome|android).)*safari/i.test(
        //   navigator.userAgent
        // );
        // this.$notify({
        //   group: "global",
        //   title: "Errore",
        //   type: "error",
        //   text:
        //     "Non è stato possibile attivare le notifiche. Riprova." +
        //     (isSafari
        //       ? " Su Safari per iOS, prova ad aggiungere il sito alla home screen con 'Condividi > Aggiungi alla Schermata Home', in caso non funzioni ancora, prova ad abilitare le notifiche da 'Impostazioni > Safari > Avanzate > Notifications'"
        //       : ""),
        //   duration: isSafari ? 15000 : 5000,
        // });
      }

      this.waitingNotifications = false;
    },
    async disableNotifications() {
      return new Promise((resolve) => {
        this.unsubscribeFromTopic("general").then(() => {
          resolve(true);
        });
      });
    },
    async subscribeToTopic(topic) {
      const token = await firebase.getToken({
        vapidKey:
          "BHi3T7yjVosvtq9nuEjw5l7AbgOxtTBoeNrDcZwklUDyTHTgcV3yxDgp6TNFHRO7bTFAwd6q4JZoGQIFJa1KDeg",
      });
      return new Promise((resolve) => {
        try {
          firebase
            .httpsCallable("subscribeToTopic")({
              token,
              topic,
            })
            .then((res) => {
              if (res.data !== true) {
                console.log(
                  `Error unsubscribing to topic ${topic}: `,
                  res.data
                );
                resolve(false);
              }

              console.log(`Subscribed to topic: ${topic}`);
              localStorage.setItem(
                this.$store.getters.localStorageNotificationsSubscriptionKey,
                "true"
              );
              resolve(true);
            })
            .catch((error) => {
              console.log(`Error subscribing to topic ${topic}: `, error);
              this.$notify({
                group: "global",
                title: "Errore",
                type: "error",
                text: "Sottoscizione non riuscita.",
                duration: 5000,
              });

              resolve(false);
            });
        } catch (error) {
          console.error(error);
          this.$notify({
            group: "global",
            title: "Errore",
            type: "error",
            text: "Sottoscizione non riuscita.",
            duration: 5000,
          });

          resolve(false);
        }
      });
    },
    async unsubscribeFromTopic(topic) {
      const token = await firebase.getToken({
        vapidKey:
          "BHi3T7yjVosvtq9nuEjw5l7AbgOxtTBoeNrDcZwklUDyTHTgcV3yxDgp6TNFHRO7bTFAwd6q4JZoGQIFJa1KDeg",
      });
      return new Promise((resolve) => {
        try {
          firebase
            .httpsCallable("unsubscribeFromTopic")({
              token,
              topic,
            })
            .then((res) => {
              if (res.data !== true) {
                console.log(
                  `Error unsubscribing to topic ${topic}: `,
                  res.data
                );
                resolve(false);
              }

              console.log(`Unsubscribed from topic: ${topic}`);
              localStorage.setItem(
                this.$store.getters.localStorageNotificationsSubscriptionKey,
                "false"
              );
              resolve(true);
            })
            .catch((error) => {
              console.log(`Error unsubscribing to topic ${topic}: `, error);
              resolve(false);
            });
        } catch (error) {
          console.error(error);
          resolve(false);
        }
      });
    },
    urlBase64ToUint8Array(base64String) {
      var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      /* eslint-disable no-useless-escape */
      var base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      var rawData = window.atob(base64);
      var outputArray = new Uint8Array(rawData.length);

      for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    async subscribeUserToPush() {
      if ("serviceWorker" in navigator) {
        return new Promise((resolve) => {
          navigator.serviceWorker
            .register("/firebase-messaging-sw.js", {
              scope: "/",
            })
            .then((registration) => {
              const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(
                  "BHi3T7yjVosvtq9nuEjw5l7AbgOxtTBoeNrDcZwklUDyTHTgcV3yxDgp6TNFHRO7bTFAwd6q4JZoGQIFJa1KDeg"
                ),
              };

              return registration.pushManager.subscribe(subscribeOptions);
            })
            .then((pushSubscription) => {
              console.log(
                "Received PushSubscription: ",
                JSON.stringify(pushSubscription)
              );
              resolve(true);
            })
            .catch((err) => {
              console.log("Error subscribing to push: ", err);
              resolve(false);
            });
        });
      } else {
        console.log("Service Worker not supported");
        this.$notify({
          group: "global",
          title: "Errore",
          type: "error",
          text: "Questo browser non supporta le notifiche.",
          duration: 5000,
        });

        return false;
      }
    },
    async changeEmailPreference(event) {
      // emailPreference
      this.waitingEmail = true;
      if (event.currentTarget.checked) {
        await Emails.create({ email: this.$store.getters.user.email })
          .then(() => {
            this.form.emailSubscription = true;
          })
          .finally(() => {
            this.waitingEmail = false;
          });
      } else {
        await Emails.delete(this.$store.getters.user.email)
          .then(() => {
            this.form.emailSubscription = false;
          })
          .finally(() => {
            this.waitingEmail = false;
          });
      }
    },
    async updateProfileAndPassword() {
      this.retUpdateProfile = {};
      this.retUpdatePassword = {};
      if (
        this.$store.getters.user.displayName != this.form.displayName ||
        this.changedInfo
      ) {
        // TODO promise.allSettled
        this.waitingUpdate = true;
        this.updateProfile().then(() => (this.waitingUpdate = false));
      }
      if (this.showUpdatePassword) {
        this.waitingUpdate = true;
        this.updatePassword().then(() => (this.waitingUpdate = false));
      }
    },
    async updateProfile() {
      this.retUpdateProfile = {};
      this.retUpdateProfile = await this.$store.dispatch(A.SAVE_PROFILE, {
        displayName: this.form.displayName,
        phone: this.form.phone ?? "",
        codiceFiscale: this.form.codiceFiscale ?? "",
        luogoNascita: this.form.luogoNascita ?? "",
        dataNascita: this.form.dataNascita ?? "",
        address: this.form.address ?? "",
        // email: this.form.email,
        password: this.form.password,
      });
    },
    async updatePassword() {
      this.retUpdatePassword = {};
      this.retUpdatePassword = await this.$store.dispatch(A.UPDATE_PASSWORD, {
        oldPassword: this.form.oldPassword,
        newPassword: this.form.password,
      });
    },
    async verifyEmail() {
      this.retVerify = {};
      this.waitingVerify = true;
      this.retVerify = await this.$store.dispatch(A.VERIFY_EMAIL);
      this.waitingVerify = false;
    },
    validateName(value) {
      if (this.$store.getters.regexName.test(value)) {
        this.invalidName = false;
      } else {
        this.invalidName = true;
      }
    },
    validatePassword(value) {
      if (this.$store.getters.regexPassword.test(value)) {
        this.invalidPassword = false;
      } else {
        this.invalidPassword = true;
      }
    },
    validateConfirmPassword(value) {
      if (
        this.$store.getters.regexPassword.test(value) &&
        value == this.form.password
      ) {
        this.invalidConfirmPassword = false;
      } else {
        this.invalidConfirmPassword = true;
      }
    },
  },
};
</script>

<style scoped>
#profile {
  max-width: 1100px;
}
</style>
