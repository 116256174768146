<template>
  <div class="content-fullheight columns mt-2 mb-6 is-flex is-vcentered">
    <div id="register" class="container">
      <div class="rounded mx-6 has-background-white">
        <div class="column is-10">
          <div class="columns">
            <div
              id="background"
              class="rounded is-hidden-mobile column is-8 mr-3 left"
            >
            </div>
            <div class="column is-5 m-5 has-text-centered">
              <h1 class="title is-3">
                Entra nel mondo di
                <p class="has-text-grey-light is-inline">Vespa Club</p>
              </h1>
              <hr />
              <div
                v-if="ret.message"
                v-bind:class="{
                  'is-danger': !ret.success,
                  'is-success': ret.success,
                }"
                class="notification rounded is-light mb-3"
              >
                {{ ret.message }}
              </div>
              <form action="#" @submit.prevent="register">
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.displayName"
                      class="input"
                      v-bind:class="{ 'is-success': !invalidName }"
                      type="text"
                      maxlength="50"
                      placeholder="Nome Cognome"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-signature"></i>
                    </span>
                    <span
                      v-if="!invalidName"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>

                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.email"
                      class="input"
                      v-bind:class="{ 'is-success': !invalidEmail }"
                      type="email"
                      placeholder="Email"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                    <span
                      v-if="!invalidEmail"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>

                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.password"
                      class="input"
                      v-bind:class="{ 'is-success': !invalidPassword }"
                      type="password"
                      placeholder="Password"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                    <span
                      v-if="!invalidPassword"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>

                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.confirmPassword"
                      class="input"
                      v-bind:class="{ 'is-success': !invalidConfirmPassword }"
                      type="password"
                      placeholder="Conferma Password"
                    />
                    <span class="icon is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                    <span
                      v-if="!invalidConfirmPassword"
                      class="icon has-text-success is-right"
                    >
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>

                <div
                  class="
                    container
                    is-size-7
                    has-text-left has-text-grey-light
                    px-5
                    pt-3
                    pb-5
                  "
                >
                  Le password devono:
                  <ul class="">
                    <li v-bind:class="{ 'has-text-danger': invalidPassword }">
                      - avere lunghezza di 8 o più caratteri
                    </li>
                    <li v-bind:class="{ 'has-text-danger': invalidPassword }">
                      - contenere almeno una lettera
                    </li>
                    <li v-bind:class="{ 'has-text-danger': invalidPassword }">
                      - contenere almeno un numero
                    </li>
                    <li
                      v-bind:class="{
                        'has-text-danger': invalidConfirmPassword,
                      }"
                    >
                      - corrispondere
                    </li>
                  </ul>
                </div>

                <button
                  v-bind:disabled="
                    invalidEmail ||
                    invalidPassword ||
                    invalidConfirmPassword ||
                    invalidName ||
                    waiting ||
                    $store.getters.isRegistering
                  "
                  class="button is-block is-dark is-fullwidth"
                  v-bind:class="{ 'is-loading': waiting }"
                >
                  Registrati
                </button>
                <br />
                <small
                  ><em class="has-text-centered"
                    >Possiedi già un account?
                    <br />
                    <router-link class="has-text-info" to="/login"
                      ><a class="has-text-centered">Accedi &gt;</a></router-link
                    ></em
                  ></small
                >
                <hr />
                <button class="button" @click.prevent="socialLogin">
                  <span class="icon"> <i class="fab fa-google"></i> </span>
                  <span>Google</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import A from "../store/action-types.js";

export default {
  name: "Register",
  metaInfo: {
    title: "Registrati",
  },
  data() {
    return {
      form: {
        displayName: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      invalidName: true,
      invalidEmail: true,
      invalidPassword: true,
      invalidConfirmPassword: true,
      waiting: false,
      ret: {
        success: false,
        message: "",
      },
    };
  },
  watch: {
    "form.displayName"(value) {
      // binding this to the data value in the email input
      // this.email = value;
      this.validateName(value);
    },
    "form.email"(value) {
      // binding this to the data value in the email input
      // this.email = value;
      this.validateEmail(value);
    },
    "form.password"(value) {
      // binding this to the data value in the email input
      this.validatePassword(value);
      this.validateConfirmPassword(this.form.confirmPassword);
    },
    "form.confirmPassword"(value) {
      // binding this to the data value in the email input
      this.validateConfirmPassword(value);
    },
  },
  methods: {
    async register() {
      this.ret = {};
      this.waiting = true;
      this.ret = await this.$store.dispatch(A.REGISTER, {
        name: this.form.displayName,
        email: this.form.email,
        password: this.form.password,
      });
      this.waiting = false;
    },
    async socialLogin() {
      this.ret = await this.$store.dispatch(A.LOGIN_GOOGLE);
      if (this.$store.state.user.isLogged)
        this.$router.replace({ name: "Home" });
    },
    validateName(value) {
      if (this.$store.getters.regexName.test(value)) {
        this.invalidName = false;
      } else {
        this.invalidName = true;
      }
    },
    validateEmail(value) {
      if (this.$store.getters.regexEmail.test(value)) {
        this.invalidEmail = false;
      } else {
        this.invalidEmail = true;
      }
    },
    validatePassword(value) {
      if (this.$store.getters.regexPassword.test(value)) {
        this.invalidPassword = false;
      } else {
        this.invalidPassword = true;
      }
    },
    validateConfirmPassword(value) {
      if (
        this.$store.getters.regexPassword.test(value) &&
        value == this.form.password
      ) {
        this.invalidConfirmPassword = false;
      } else {
        this.invalidConfirmPassword = true;
      }
    },
  },
};
</script>

<style scoped>
#register {
  max-width: 1100px;
}

#background {
  background-image: url("../assets/vespe.jpg");
  mask-image: linear-gradient(
    to right,
    rgb(160, 122, 122) 50%,
    transparent 100%
  );

  background-size: cover;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  /* background-position: center; */
}
</style>