<template>
  <tr class="table-row-user">
    <td>
      <p class="mr-2 is-inline">{{ user.displayName }}</p>
      <p v-if="user.isSuperAdmin" class="tag is-dark mr-2">Manager</p>
      <p v-if="user.claims.admin" class="tag is-warning mr-2">Admin</p>
      <p v-if="user.claims.hasAccess" class="tag is-danger mr-2">
        Socio dal
        {{
          user.associatedFrom != null
            ? user.associatedFrom.toDate().toLocaleDateString("it-IT")
            : ""
        }}
      </p>
    </td>
    <td>
      <p class="mr-2 is-inline">{{ user.email }}</p>
      <p v-if="user.emailVerified" class="tag is-success mr-2">Verificata</p>
    </td>
    <td>
      {{
        user.createdAt != null
          ? user.createdAt.toDate().toLocaleDateString("it-IT")
          : ""
      }}
    </td>
    <td>{{ user.phone }}</td>
    <td>{{ user.codiceFiscale }}</td>
    <td>{{ user.address }}</td>
    <td>
      {{ user.dataNascita }} -
      {{ user.luogoNascita }}
    </td>
    <td class="has-text-right">
      <div
        v-if="!user.isSuperAdmin"
        v-bind:class="{
          'is-loading': waiting,
        }"
        class="select is-small mb-1 is-info"
      >
        <select
          v-bind:disabled="waiting || user.email == $store.getters.user.email"
          v-on:change="changeRole(user.id, $event)"
          class=""
        >
          <option v-bind:selected="user.claims.admin == true" value="true">
            Admin
          </option>
          <option v-bind:selected="user.claims.admin == false" value="false">
            Utente
          </option>
        </select>
      </div>
      <p
        v-if="!user.claims.hasAccess"
        v-bind:disabled="waiting"
        v-bind:class="{
          'is-loading': waiting,
        }"
        class="button is-small is-danger is-outlined m-1"
        @click="makeSocio(user.id)"
      >
        Rendi Socio
      </p>
      <p
        v-if="!user.claims.admin"
        v-bind:disabled="waiting"
        v-bind:class="{
          'is-loading': waiting,
        }"
        class="button is-small is-danger m-1"
        @click="deleteUser(user.id)"
      >
        Elimina
      </p>
    </td>
  </tr>
</template>

<style scoped>
.notification {
  min-width: 300px;
}
</style>

<script>
import firebase from "../firebase";
import { httpsCallable } from "firebase/functions";

export default {
  name: "TableRowUser",
  data() {
    return {
      error: null,
      waiting: false,
    };
  },
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
  methods: {
    async makeSocio(uid) {
      this.waiting = true;

      let setCustomClaims = httpsCallable(
        firebase.functions,
        "setCustomClaims",
        {
          timeout: 15000,
        }
      );
      // let newAdminValue =
      //   String(this.user.claims.admin).toLowerCase() === "true";

      let payload = {
        uid: uid,
        claims: {
          // admin: newAdminValue,// invariato
          hasAccess: true,
        },
      };

      setCustomClaims(payload)
        .then((result) => {
          this.user.claims.hasAccess = !!result.data;
        })
        .catch(() => {
          this.user.claims.hasAccess = false;
          // console.debug(error);
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    async deleteUser(userId) {
      if (
        confirm(
          "Sei sicuro di voler rimuovere l'utente?\nI dati saranno persi e dovrà registrarsi nuovamente.\nNon è possibile revocare l'operazione una volta avviata."
        ) == true
      ) {
        let deleteUserCall = httpsCallable(firebase.functions, "deleteUser", {
          timeout: 30000,
        });

        this.waiting = true;

        deleteUserCall({ uid: userId })
          .then((value) => {
            if (value) {
              this.$notify({
                group: "global",
                title: "Utente eliminato",
                type: "info",
                duration: 5000,
              });
            } else {
              this.$notify({
                group: "global",
                title: "Errore",
                type: "error",
                text: "Impossibile eliminare l'utente",
                duration: 5000,
              });
            }
          })
          .catch((error) => {
            console.error(error);
            this.$notify({
              group: "global",
              title: "Errore",
              type: "error",
              text: error || "Impossibile eliminare l'utente",
              duration: 5000,
            });
          })
          .finally(() => {
            this.waiting = false;
          });
      }
    },
    async changeRole(uid, event) {
      this.waiting = true;

      let setCustomClaims = httpsCallable(
        firebase.functions,
        "setCustomClaims",
        {
          timeout: 15000,
        }
      );
      let newAdminValue = String(event.target.value).toLowerCase() === "true";

      let payload = {
        uid: uid,
        claims: {
          admin: newAdminValue,
          // hasAccess: this.user.claims.hasAccess, // invariato
        },
      };

      setCustomClaims(payload)
        .then((result) => {
          if (!result.data) {
            //fallisce e inverto il valore
            this.user.claims.admin = !newAdminValue;
          }
          // console.log(result.data);
        })
        .catch(() => {
          this.user.claims.admin = !newAdminValue;
          // console.debug(error);
        })
        .finally(() => {
          this.waiting = false;
        });
    },
  },
};
</script>
