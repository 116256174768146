<template>
  <tr class="table-row-news">
    <td>
      <textarea
        v-model="news.titolo"
        v-bind:disabled="waiting"
        class="input textarea"
        type="text"
        v-on:input="edited = true"
        maxlength="100"
        placeholder="Titolo"
      />
    </td>
    <td>
      <input
        v-model="news.data"
        v-bind:disabled="waiting"
        class="input"
        type="datetime-local"
        v-on:input="edited = true"
      />
    </td>
    <td>
      <textarea
        v-model="news.descrizione"
        v-bind:disabled="waiting"
        class="input textarea"
        type="text"
        v-on:input="edited = true"
        maxlength="2000"
        placeholder="Descrizione"
      />
    </td>
    <td class="control">
      <div class="select is-small is-dark">
        <select
          v-bind:disabled="waiting"
          @change="changeImage($event)"
          v-on:change="edited = true"
          class=""
        >
          <option
            v-for="(image, index) in images"
            v-bind:key="index"
            v-bind:selected="image.fullPath == news.immagine"
            v-bind:value="image.fullPath"
          >
            {{ image.name }}
          </option>
        </select>
      </div>
    </td>
    <td>
      <button
        class="button is-info mr-2 my-1"
        v-bind:disabled="waiting || !edited"
        v-bind:class="{
          'is-loading': waiting,
        }"
        @click.prevent="save"
      >
        Salva modifiche
      </button>
      <button
        class="button is-danger mr-2 my-1"
        v-bind:disabled="waiting"
        v-bind:class="{
          'is-loading': waiting,
        }"
        @click.prevent="remove"
      >
        Elimina
      </button>
      <button
        class="button is-primary mr-2 my-1"
        @click.prevent="
          $emit('openModal', {
            title: titleNotification(news),
            description: news.descrizione,
            clickAction: 'https://vespaclubmarostica.it/news'
          })
        "
      >
        Notifica
      </button>
      <div v-if="error" class="has-text-danger has-text-centered mb-3">
        {{ error }}
      </div>
    </td>
  </tr>
</template>

<script>
// import firebase from "../firebase";
import News from "../services/news";

export default {
  name: "TableRowNews",
  data() {
    return {
      error: null,
      waiting: false,
      edited: false,
    };
  },
  props: {
    news: {
      required: true,
      type: Object,
    },
    images: {
      required: true,
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    titleNotification() {
      return (news) => {
        return news.titolo + " - " + new Date(news.data).toLocaleDateString();
      };
    },
  },
  methods: {
    changeImage(event) {
      this.news.immagine = event.target.value;
    },
    async save() {
      this.waiting = true;
      this.error = "";

      await News.update(this.news.id, this.news)
        .then(() => {
          this.edited = false;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    async remove() {
      if (confirm("Sei sicuro di voler eliminare questa notizia?") == true) {
        this.waiting = true;
        this.error = "";

        await News.delete(this.news.id)
          .then(() => {
            this.$emit("news-deleted", this.news);
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.waiting = false;
          });
      }
    },
  },
};
</script>