<template>
  <div class="content-fullheight container users py-6">
    <div class="columns">
      <aside class="is-hidden-touch menu column px-1 is-2-tablet is-1-desktop">
        <p class="menu-label">Gestione</p>
        <ul class="menu-list">
          <li><a href="#newsletter" class="rounded">Newsletter</a></li>
          <li><a href="#banner" class="rounded">Banner</a></li>
          <li><a href="#photos" class="rounded">Foto</a></li>
          <li><a href="#photosclothing" class="rounded">Foto Abb.</a></li>
          <li><a href="#news" class="rounded">Notizie</a></li>
          <li><a href="#clothing" class="rounded">Abbigliam.</a></li>
        </ul>
        <hr />
        <p class="menu-label">Utenti</p>
        <ul class="menu-list">
          <li><a href="#events" class="rounded">Eventi</a></li>
          <li><a href="#list" class="rounded">Lista utenti</a></li>
        </ul>
      </aside>

      <div class="is-hidden-touch is-divider-vertical"></div>

      <div class="column">
        <!-- notification -->
        <div class="is-grid">
          <div class="table-container column">
            <div id="newsletter" class="title has-text-left ml-3">
              <p class="is-inline mr-4">Notifica</p>
              <button
                class="button is-primary"
                @click="
                  openModal({
                    title: '',
                    description: '',
                    clickAction: 'https://www.vespaclubmarostica.it/',
                  })
                "
              >
                <p class="">Invia notifica...</p>
              </button>
            </div>
          </div>
        </div>

        <hr />
        <!-- newsletter -->
        <div class="is-grid">
          <div class="table-container column">
            <div id="newsletter" class="title has-text-left ml-3">
              <p class="is-inline mr-4">Newsletter</p>
              <button
                class="button is-info has-icons-right"
                @click="downloadCsvNewsletter"
                v-bind:disabled="waitingNewsletter"
                v-bind:class="{
                  'is-loading': waitingNewsletter,
                }"
              >
                <p class="is-hidden-mobile">Scarica lista in file Csv</p>
                <p class="is-hidden-tablet">Scarica Csv</p>
                <span class="icon is-right ml-1">
                  <i class="fas fa-download"></i>
                </span>
              </button>
            </div>
            <div
              v-if="errorNewsletter"
              class="has-text-danger has-text-centered mb-3"
            >
              {{ errorNewsletter }}
            </div>
          </div>
        </div>

        <hr />
        <!-- banner -->
        <div>
          <div id="banner" class="title mb-0 has-text-left ml-3">
            <div class="level mx-3 is-inline">
              <p class="is-inline mr-4">Banner</p>
              <button
                v-if="!loadedBanners"
                class="button is-dark has-icons-right"
                @click="loadBanners"
                v-bind:disabled="waitingBanner"
                v-bind:class="{
                  'is-loading': waitingBanner,
                }"
              >
                Gestisci
                <span class="icon is-right ml-1">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </div>
          </div>
          <div class="is-grid">
            <div class="table-container column">
              <table
                v-if="loadedBanners"
                class="table is-striped is-hoverable is-fullwidth has-text-left"
              >
                <thead>
                  <tr>
                    <th>Pagina</th>
                    <th class="column-tag">Tag</th>
                    <th class="column-messaggio">Messaggio</th>
                    <th>Colore</th>
                    <th>Abilitato</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRowBanner
                    v-for="banner in banners"
                    v-bind:banner="banner"
                    v-bind:key="banner.id"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <hr />
        <!-- photos -->
        <div>
          <div id="photos" class="title mb-0 has-text-left ml-3">
            <div class="level mx-3 is-inline">
              <p class="is-inline mr-4">Foto</p>

              <button
                v-if="loadedPhotos"
                class="button mr-3 my-1"
                v-bind:disabled="waitingUploadPhotos"
                @click="choosePhotos"
              >
                Scegli foto
              </button>
              <input
                type="file"
                ref="inputPhoto"
                multiple
                style="display: none"
                @change="previewImage"
                accept="image/*"
              />

              <button
                v-if="loadedPhotos"
                v-bind:disabled="arrImages.length == 0 || waitingUploadPhotos"
                v-bind:class="{
                  'is-loading': waitingUploadPhotos,
                }"
                class="button is-dark my-1"
                @click="uploadPhotos"
              >
                Carica sul server
                {{
                  arrImages.length > 0 ? "(" + arrImages.length + " files)" : ""
                }}
              </button>

              <button
                v-if="!loadedPhotos"
                class="button is-dark has-icons-right my-1"
                @click="loadPhotos"
                v-bind:disabled="waitingPhotos"
                v-bind:class="{
                  'is-loading': waitingPhotos,
                }"
              >
                Gestisci
                <span class="icon is-right ml-1">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </div>
          </div>

          <div class="is-grid">
            <div class="table-container column">
              <div
                v-if="errorPhotos"
                class="has-text-danger has-text-centered mb-3"
              >
                {{ errorPhotos }}
              </div>
              <table
                v-if="loadedPhotos && arrImages.length > 0"
                class="table is-striped is-hoverable is-fullwidth has-text-left"
              >
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Caricamento</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ph, index) in arrImages" v-bind:key="ph.name">
                    <td>
                      {{ ph.name }}
                      <small
                        class=""
                        v-bind:class="{
                          'has-text-grey':
                            (ph.size / (1024 * 1024)).toFixed(2) <= 0.5,
                          'has-text-warning':
                            (ph.size / (1024 * 1024)).toFixed(2) > 0.5 &&
                            (ph.size / (1024 * 1024)).toFixed(2) <= 0.7,
                          'has-text-orange':
                            (ph.size / (1024 * 1024)).toFixed(2) > 0.7 &&
                            (ph.size / (1024 * 1024)).toFixed(2) <= 1,
                          'has-text-danger':
                            (ph.size / (1024 * 1024)).toFixed(2) > 1,
                        }"
                        >({{ (ph.size / (1024 * 1024)).toFixed(2) }} MB)</small
                      >
                    </td>
                    <td>
                      <progress
                        class="progress is-success"
                        v-bind:value="arrPercentages[index]"
                        max="100"
                      >
                        {{ arrPercentages[index] }}%
                      </progress>
                    </td>
                    <td>
                      <button
                        class="delete is-pulled-right"
                        v-if="!waitingUploadPhotos"
                        @click.prevent="removePhoto(index)"
                        aria-label="close"
                      ></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr v-if="loadedPhotos && arrImages.length > 0" class="mx-6" />

          <div class="is-grid has-text-left">
            <div class="">
              <div v-if="loadedPhotos">
                <div
                  class="is-inline is-relative m-2"
                  v-for="(ph, index) in photos"
                  v-bind:key="index"
                >
                  <div class="dropdown is-hoverable">
                    <div class="dropdown-trigger">
                      <div
                        v-if="photosUrls[index]"
                        class="photo is-inline is-relative"
                      >
                        <img
                          v-bind:src="photosUrls[index]"
                          alt="Immagine Caricata"
                          aria-haspopup="true"
                          class="image rounded is-inline is-96x96"
                          v-bind:aria-controls="'dropdown-menu-photo' + index"
                        />
                      </div>
                      <div
                        class="dropdown-menu"
                        v-bind:id="'dropdown-menu-photo' + index"
                        role="menu"
                      >
                        <div class="dropdown-content">
                          <div class="dropdown-item is-flex p-2 is-fullwidth">
                            <button
                              class="
                                delete
                                is-inline
                                has-background-danger
                                is-pulled-left
                                m-1
                                mr-3
                              "
                              v-if="
                                !waitingUploadPhotos && !waitingDeletePhotos
                              "
                              @click.prevent="deletePhoto(index)"
                              aria-label="close"
                            ></button>
                            <span class="is-inline">{{ ph.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <!-- photosclothing -->
        <div>
          <div id="photosclothing" class="title mb-0 has-text-left ml-3">
            <div class="level mx-3 is-inline">
              <p class="is-inline mr-4">Foto Abbigliamento</p>

              <button
                v-if="loadedPhotosClothing"
                class="button mr-3 my-1"
                v-bind:disabled="waitingUploadPhotosClothing"
                @click="choosePhotosClothing"
              >
                Scegli foto
              </button>
              <input
                type="file"
                ref="inputPhotoClothing"
                multiple
                style="display: none"
                @change="previewImageClothing"
                accept="image/*"
              />

              <button
                v-if="loadedPhotosClothing"
                v-bind:disabled="
                  arrImagesClothing.length == 0 || waitingUploadPhotosClothing
                "
                v-bind:class="{
                  'is-loading': waitingUploadPhotosClothing,
                }"
                class="button is-dark my-1"
                @click="uploadPhotosClothing"
              >
                Carica sul server
                {{
                  arrImagesClothing.length > 0
                    ? "(" + arrImagesClothing.length + " files)"
                    : ""
                }}
              </button>

              <button
                v-if="!loadedPhotosClothing"
                class="button is-dark has-icons-right my-1"
                @click="loadPhotosClothing"
                v-bind:disabled="waitingPhotosClothing"
                v-bind:class="{
                  'is-loading': waitingPhotosClothing,
                }"
              >
                Gestisci
                <span class="icon is-right ml-1">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </div>
          </div>

          <div class="is-grid">
            <div class="table-container column">
              <div
                v-if="errorPhotosClothing"
                class="has-text-danger has-text-centered mb-3"
              >
                {{ errorPhotosClothing }}
              </div>
              <table
                v-if="loadedPhotosClothing && arrImagesClothing.length > 0"
                class="table is-striped is-hoverable is-fullwidth has-text-left"
              >
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Caricamento</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ph, index) in arrImagesClothing"
                    v-bind:key="ph.name"
                  >
                    <td>
                      {{ ph.name }}
                      <small
                        class=""
                        v-bind:class="{
                          'has-text-grey':
                            (ph.size / (1024 * 1024)).toFixed(2) <= 0.5,
                          'has-text-warning':
                            (ph.size / (1024 * 1024)).toFixed(2) > 0.5 &&
                            (ph.size / (1024 * 1024)).toFixed(2) <= 0.7,
                          'has-text-orange':
                            (ph.size / (1024 * 1024)).toFixed(2) > 0.7 &&
                            (ph.size / (1024 * 1024)).toFixed(2) <= 1,
                          'has-text-danger':
                            (ph.size / (1024 * 1024)).toFixed(2) > 1,
                        }"
                        >({{ (ph.size / (1024 * 1024)).toFixed(2) }} MB)</small
                      >
                    </td>
                    <td>
                      <progress
                        class="progress is-success"
                        v-bind:value="arrPercentagesClothing[index]"
                        max="100"
                      >
                        {{ arrPercentagesClothing[index] }}%
                      </progress>
                    </td>
                    <td>
                      <button
                        class="delete is-pulled-right"
                        v-if="!waitingUploadPhotosClothing"
                        @click.prevent="removePhotoClothing(index)"
                        aria-label="close"
                      ></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr
            v-if="loadedPhotosClothing && arrImagesClothing.length > 0"
            class="mx-6"
          />

          <div class="is-grid has-text-left">
            <div class="">
              <div v-if="loadedPhotosClothing">
                <div
                  class="is-inline is-relative m-2"
                  v-for="(ph, index) in photosClothing"
                  v-bind:key="index"
                >
                  <div class="dropdown is-hoverable">
                    <div class="dropdown-trigger">
                      <div
                        v-if="photosUrlsClothing[index]"
                        class="photo is-inline is-relative"
                      >
                        <img
                          v-bind:src="photosUrlsClothing[index]"
                          alt="Immagine Caricata"
                          aria-haspopup="true"
                          class="image rounded is-inline is-96x96"
                          v-bind:aria-controls="'dropdown-menu-photo' + index"
                        />
                      </div>
                      <div
                        class="dropdown-menu"
                        v-bind:id="'dropdown-menu-photo' + index"
                        role="menu"
                      >
                        <div class="dropdown-content">
                          <div class="dropdown-item is-flex p-2 is-fullwidth">
                            <button
                              class="
                                delete
                                is-inline
                                has-background-danger
                                is-pulled-left
                                m-1
                                mr-3
                              "
                              v-if="
                                !waitingUploadPhotosClothing &&
                                !waitingDeletePhotosClothing
                              "
                              @click.prevent="deletePhotoClothing(index)"
                              aria-label="close"
                            ></button>
                            <span class="is-inline">{{ ph.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <!-- news -->
        <div>
          <div id="news" class="title mb-0 has-text-left ml-3">
            <div class="level mx-3 is-inline">
              <p class="is-inline mr-4">Notizie</p>
              <button
                v-if="!loadedNews"
                class="button is-dark has-icons-right"
                @click="loadNews"
                v-bind:disabled="waitingNews || waitingUploadPhotos"
                v-bind:class="{
                  'is-loading': waitingNews,
                }"
              >
                Gestisci
                <span class="icon is-right ml-1">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </div>
          </div>
          <div class="is-grid">
            <div class="table-container column">
              <div
                v-if="errorNews"
                class="has-text-danger has-text-centered mb-3"
              >
                {{ errorNews }}
              </div>
              <table
                v-if="loadedNews"
                class="table is-striped is-hoverable is-fullwidth has-text-left"
              >
                <thead>
                  <tr>
                    <th class="column-tag">Titolo</th>
                    <th class="column-tag">Data</th>
                    <th class="column-messaggio">Descrizione</th>
                    <th class="column-tag">Immagine</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="has-background-grey-lighter">
                    <td>
                      <textarea
                        v-model="newNews.titolo"
                        v-bind:disabled="waitingCreationNews"
                        class="input textarea"
                        v-bind:class="{
                          'is-danger': !newNews.titolo,
                        }"
                        type="text"
                        maxlength="80"
                        placeholder="Titolo"
                      />
                    </td>
                    <td>
                      <input
                        v-model="newNews.data"
                        v-bind:disabled="waitingCreationNews"
                        class="input"
                        v-bind:class="{
                          'is-danger': !newNews.data,
                        }"
                        type="datetime-local"
                      />
                      <em class="has-text-success">Nuova notizia</em>
                    </td>
                    <td>
                      <textarea
                        v-model="newNews.descrizione"
                        v-bind:disabled="waitingCreationNews"
                        class="input textarea"
                        type="text"
                        maxlength="300"
                        placeholder="Descrizione"
                      />
                    </td>
                    <td class="control">
                      <div class="select is-small is-dark">
                        <select
                          v-bind:disabled="waitingCreationNews"
                          @change="changeNewsImage($event)"
                          class=""
                        >
                          <option
                            v-for="(image, index) in photos"
                            v-bind:key="index"
                            v-bind:selected="image.fullPath == newNews.immagine"
                            v-bind:value="image.fullPath"
                          >
                            {{ image.name }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <button
                        class="button is-success mr-2 my-1"
                        v-bind:disabled="
                          waitingCreationNews ||
                          !newNews.data ||
                          !newNews.immagine ||
                          !newNews.titolo
                        "
                        v-bind:class="{
                          'is-loading': waitingCreationNews,
                        }"
                        @click.prevent="createNewNews"
                      >
                        Crea Notizia
                      </button>
                      <div
                        v-if="errorCreationNews"
                        class="has-text-danger has-text-centered mb-3"
                      >
                        {{ errorCreationNews }}
                      </div>
                    </td>
                  </tr>
                  <TableRowNews
                    v-for="currentNews in news"
                    v-bind:news="currentNews"
                    v-on:news-deleted="newsDeleted"
                    v-bind:images="photos"
                    v-bind:key="currentNews.id"
                    v-on:openModal="openModal"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <hr />
        <!-- clothing -->
        <div>
          <div id="clothing" class="title mb-0 has-text-left ml-3">
            <div class="level mx-3 is-inline">
              <p class="is-inline mr-4">Abbigliamento</p>
              <button
                v-if="!loadedClothing"
                class="button is-dark has-icons-right"
                @click="loadClothing"
                v-bind:disabled="waitingClothing || waitingUploadPhotos"
                v-bind:class="{
                  'is-loading': waitingClothing,
                }"
              >
                Gestisci
                <span class="icon is-right ml-1">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </div>
          </div>
          <div class="is-grid">
            <div class="table-container column">
              <div
                v-if="errorClothing"
                class="has-text-danger has-text-centered mb-3"
              >
                {{ errorClothing }}
              </div>
              <table
                v-if="loadedClothing"
                class="table is-striped is-hoverable is-fullwidth has-text-left"
              >
                <thead>
                  <tr>
                    <th class="column-tag">Titolo</th>
                    <th class="column-tag">Immagine</th>
                    <th class="column-price">Prezzo</th>
                    <th class="column-available">Segna Disponibile</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="has-background-grey-lighter">
                    <td>
                      <textarea
                        v-model="newClothing.titolo"
                        v-bind:disabled="waitingCreationClothing"
                        class="input textarea"
                        v-bind:class="{
                          'is-danger': !newClothing.titolo,
                        }"
                        type="text"
                        maxlength="80"
                        placeholder="Titolo"
                      />
                    </td>
                    <td class="control">
                      <div class="select is-small is-dark">
                        <select
                          v-bind:disabled="waitingCreationClothing"
                          @change="changeClothingImage($event)"
                          class=""
                        >
                          <option
                            v-for="(image, index) in photosClothing"
                            v-bind:key="index"
                            v-bind:selected="
                              image.fullPath == newClothing.immagine
                            "
                            v-bind:value="image.fullPath"
                          >
                            {{ image.name }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <input
                        v-model="newClothing.prezzo"
                        v-bind:disabled="waitingCreationClothing"
                        class="input"
                        type="text"
                        maxlength="12"
                        placeholder="Prezzo"
                      />
                    </td>
                    <td class="has-text-right">
                      <input
                        v-model="newClothing.available"
                        v-bind:disabled="waitingCreationClothing"
                        class="switch is-info"
                        type="checkbox"
                        v-bind:id="'newswitchAvailable'"
                        v-bind:name="'newswitchAvailable'"
                      />
                      <label v-bind:for="'newswitchAvailable'"></label>
                    </td>
                    <td>
                      <button
                        class="button is-success mr-2 my-1"
                        v-bind:disabled="
                          waitingCreationClothing ||
                          !newClothing.immagine ||
                          !newClothing.titolo
                        "
                        v-bind:class="{
                          'is-loading': waitingCreationClothing,
                        }"
                        @click.prevent="createNewClothing"
                      >
                        Crea Abbigliamento
                      </button>
                      <div
                        v-if="errorCreationClothing"
                        class="has-text-danger has-text-centered mb-3"
                      >
                        {{ errorCreationClothing }}
                      </div>
                    </td>
                  </tr>
                  <TableRowClothing
                    v-for="currentClothing in clothings"
                    v-bind:clothing="currentClothing"
                    v-on:clothing-deleted="clothingDeleted"
                    v-bind:images="photosClothing"
                    v-bind:key="currentClothing.id"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <hr />
        <!-- events -->
        <div>
          <div id="events" class="title mb-0 has-text-left ml-3">
            <div class="level mx-3 is-inline">
              <p class="is-inline mr-4">Eventi</p>
              <button
                v-if="!loadedEvents"
                class="button is-dark has-icons-right"
                @click="loadEvents"
                v-bind:disabled="waitingEvents || waitingUploadPhotos"
                v-bind:class="{
                  'is-loading': waitingEvents,
                }"
              >
                Gestisci
                <span class="icon is-right ml-1">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
            </div>
          </div>
          <div class="is-grid">
            <div class="table-container column">
              <div
                v-if="errorEvents"
                class="has-text-danger has-text-centered mb-3"
              >
                {{ errorEvents }}
              </div>
              <table
                v-if="loadedEvents"
                class="table is-striped is-hoverable is-fullwidth has-text-left"
              >
                <thead>
                  <tr>
                    <th class="column-messaggio">Titolo</th>
                    <th class="column-tag">Data</th>
                    <th class="column-messaggio">Iscritti</th>
                    <th class="column-messaggio">Descrizione</th>
                    <th class="column-tag">Ritrovo</th>
                    <th class="column-tag">Scadenza</th>
                    <th class="column-tag">Costo Soci</th>
                    <th class="column-tag">Costo Non Soci</th>
                    <th class="column-tag">Pasto Disponibile</th>
                    <th class="column-tag">Visibile ai Non Soci</th>
                    <th class="column-tag">Immagine</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="has-background-grey-lighter">
                    <td>
                      <textarea
                        v-model="newEvent.titolo"
                        v-bind:disabled="waitingCreationEvent"
                        class="input textarea"
                        v-bind:class="{
                          'is-danger': !newEvent.titolo,
                        }"
                        type="text"
                        maxlength="80"
                        placeholder="Titolo"
                      />
                    </td>
                    <td>
                      <input
                        v-model="newEvent.data"
                        v-bind:disabled="waitingCreationEvent"
                        class="input"
                        v-bind:class="{
                          'is-danger': !newEvent.data,
                        }"
                        type="datetime-local"
                      />
                      <em class="has-text-success">Nuovo evento</em>
                    </td>
                    <td>-</td>
                    <td>
                      <textarea
                        v-model="newEvent.descrizione"
                        v-bind:disabled="waitingCreationEvent"
                        class="input textarea"
                        type="text"
                        maxlength="300"
                        placeholder="Descrizione"
                      />
                    </td>
                    <td>
                      <textarea
                        v-model="newEvent.ritrovo"
                        v-bind:disabled="waitingCreationEvent"
                        class="input textarea"
                        type="text"
                        maxlength="100"
                        placeholder="Ritrovo"
                      />
                    </td>
                    <td>
                      <input
                        v-model="newEvent.scadenza"
                        v-bind:disabled="waitingCreationEvent"
                        class="input"
                        type="datetime-local"
                      />
                    </td>
                    <td>
                      <input
                        v-model="newEvent.costoSoci"
                        v-bind:disabled="waitingCreationEvent"
                        class="input"
                        type="text"
                        maxlength="15"
                        placeholder="Costo soci"
                      />
                    </td>
                    <td>
                      <input
                        v-model="newEvent.costoNonSoci"
                        v-bind:disabled="waitingCreationEvent"
                        class="input"
                        type="text"
                        maxlength="15"
                        placeholder="Costo non soci"
                      />
                    </td>
                    <td class="control has-text-right">
                      <div class="is-small is-info">
                        <div class="field">
                          <input
                            v-bind:disabled="waitingCreationEvent"
                            id="switchNewPasto"
                            type="checkbox"
                            name="switchNewPasto"
                            class="switch is-info"
                            v-model="newEvent.pasto"
                          />
                          <label for="switchNewPasto"></label>
                        </div>
                      </div>
                    </td>
                    <td class="control has-text-right">
                      <div class="is-small is-info">
                        <div class="field">
                          <input
                            v-bind:disabled="waitingCreationEvent"
                            id="switchNewVisibleToNonSoci"
                            type="checkbox"
                            name="switchNewVisibleToNonSoci"
                            class="switch is-info"
                            v-model="newEvent.visibleToNonSoci"
                          />
                          <label for="switchNewVisibleToNonSoci"></label>
                        </div>
                      </div>
                    </td>
                    <td class="control">
                      <div class="select is-small mb-1 is-dark">
                        <select
                          v-bind:disabled="waitingCreationEvent"
                          @change="changeEventImage($event, 0)"
                          class=""
                        >
                          <option
                            key="unset1"
                            value=""
                            v-bind:selected="!newEvent.immagine[0]"
                          >
                            -
                          </option>
                          <option
                            v-for="(image, index) in photos"
                            v-bind:key="index"
                            v-bind:selected="
                              image.fullPath == newEvent.immagine[0]
                            "
                            v-bind:value="image.fullPath"
                          >
                            {{ image.name }}
                          </option>
                        </select>
                      </div>
                      <div class="select is-small my-1 is-dark">
                        <select
                          v-bind:disabled="waitingCreationEvent"
                          @change="changeEventImage($event, 1)"
                          class=""
                        >
                          <option
                            key="unset1"
                            value=""
                            v-bind:selected="!newEvent.immagine[1]"
                          >
                            -
                          </option>
                          <option
                            v-for="(image, index) in photos"
                            v-bind:key="index"
                            v-bind:selected="
                              image.fullPath == newEvent.immagine[1]
                            "
                            v-bind:value="image.fullPath"
                          >
                            {{ image.name }}
                          </option>
                        </select>
                      </div>
                      <div class="select is-small my-1 is-dark">
                        <select
                          v-bind:disabled="waitingCreationEvent"
                          @change="changeEventImage($event, 2)"
                          class=""
                        >
                          <option
                            key="unset1"
                            value=""
                            v-bind:selected="!newEvent.immagine[2]"
                          >
                            -
                          </option>
                          <option
                            v-for="(image, index) in photos"
                            v-bind:key="index"
                            v-bind:selected="
                              image.fullPath == newEvent.immagine[2]
                            "
                            v-bind:value="image.fullPath"
                          >
                            {{ image.name }}
                          </option>
                        </select>
                      </div>
                      <div class="select is-small mt-1 is-dark">
                        <select
                          v-bind:disabled="waitingCreationEvent"
                          @change="changeEventImage($event, 3)"
                          class=""
                        >
                          <option
                            key="unset1"
                            value=""
                            v-bind:selected="!newEvent.immagine[3]"
                          >
                            -
                          </option>
                          <option
                            v-for="(image, index) in photos"
                            v-bind:key="index"
                            v-bind:selected="
                              image.fullPath == newEvent.immagine[3]
                            "
                            v-bind:value="image.fullPath"
                          >
                            {{ image.name }}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <button
                        class="button is-success mr-2 my-1"
                        v-bind:disabled="
                          waitingCreationEvent ||
                          !newEvent.data ||
                          !newEvent.immagine ||
                          !newEvent.titolo
                        "
                        v-bind:class="{
                          'is-loading': waitingCreationEvent,
                        }"
                        @click.prevent="createNewEvent"
                      >
                        Crea Evento
                      </button>
                      <div
                        v-if="errorCreationEvent"
                        class="has-text-danger has-text-centered mb-3"
                      >
                        {{ errorCreationEvent }}
                      </div>
                    </td>
                  </tr>
                  <TableRowEvent
                    v-for="event in events"
                    v-bind:event="event"
                    v-on:event-deleted="eventDeleted"
                    v-bind:images="photos"
                    v-bind:key="event.id"
                    v-on:openModal="openModal"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <hr />
        <!-- lista utenti -->
        <div>
          <div id="list" class="title mb-0 has-text-left ml-3">
            <div class="level mx-3 is-inline">
              <p class="is-inline mr-3">
                Lista utenti
                <span v-if="loadedUsers">
                  ({{ filteredUsers.length }}/{{ users.length }})
                </span>
              </p>
              <button
                v-if="!loadedUsers"
                class="button is-inline is-dark has-icons-right my-1"
                @click="loadUsers"
                v-bind:disabled="waitingUsers"
                v-bind:class="{
                  'is-loading': waitingUsers,
                }"
              >
                Gestisci
                <span class="icon is-right ml-1">
                  <i class="fas fa-chevron-down"></i>
                </span>
              </button>
              <button
                v-if="loadedUsers"
                class="
                  button
                  is-inline is-pulled-right is-dark
                  has-icons-right
                  my-1
                  mr-3
                "
                @click="removeAllSocio"
                v-bind:disabled="waitingUsers || waitingRemoveSoci"
                v-bind:class="{
                  'is-loading': waitingUsers || waitingRemoveSoci,
                }"
              >
                <p class="is-hidden-mobile is-inline">Rimuovi socio a tutti</p>
                <p class="is-hidden-tablet is-inline">Rimuovi socio</p>
                <span class="icon is-right ml-1">
                  <i class="fas fa-user-times"></i>
                </span>
              </button>
              <div v-if="loadedUsers" class="control has-icons-left mt-3 mx-3">
                <input
                  v-model="filterValue"
                  class="input"
                  type="text"
                  placeholder="Filtra per nome o email..."
                />
                <span class="icon is-left">
                  <i class="fas fa-search" aria-hidden="true"></i>
                </span>

                <div
                  v-if="loadedUsers"
                  class="control columns is-flex is-vcentered pt-5 pl-4"
                >
                  <div class="is-inline">Visualizza solo:</div>
                  <div class="select ml-3 is-small">
                    <select v-on:change="changeFilter($event)" class="pl-1">
                      <option v-bind:selected="filterList == '0'" value="0">
                        Tutti
                      </option>
                      <option v-bind:selected="filterList == '1'" value="1">
                        Soci
                      </option>
                      <option v-bind:selected="filterList == '2'" value="2">
                        Non soci
                      </option>
                      <option v-bind:selected="filterList == '3'" value="3">
                        Soci (no admin)
                      </option>
                      <option v-bind:selected="filterList == '4'" value="4">
                        Non soci (no admin)
                      </option>
                      <option v-bind:selected="filterList == '5'" value="5">
                        Admin
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="is-grid">
            <div class="table-container column">
              <div
                v-if="errorUsers"
                class="has-text-danger has-text-centered mb-3"
              >
                {{ errorUsers }}
              </div>
              <table
                v-if="loadedUsers"
                class="table is-striped is-hoverable is-fullwidth has-text-left"
              >
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Registrazione</th>
                    <th>Telefono</th>
                    <th>Codice Fiscale</th>
                    <th>Indirizzo</th>
                    <th>Data e Luogo nascita</th>
                    <th class="has-text-right">Ruolo</th>
                  </tr>
                </thead>
                <tbody>
                  <TableRowUser
                    v-for="user in filteredUsers"
                    v-bind:user="user"
                    v-bind:key="user.id"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODAL  -->
    <div id="modalNotification" class="modal p-4">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="modal-card-title">
            <span class="is-inline">Invia Notifica</span>
            <div class="mt-2 is-inline-desktop">
              <p class="title is-4 is-inline-block has-text-danger mr-2"></p>
            </div>
          </div>
          <button
            class="delete"
            @click.prevent="closeModal"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <p class="control is-expanded has-icons-left">
              <input
                v-model="newNotification.title"
                v-bind:disabled="waitingNotification"
                class="input"
                type="text"
                maxlength="60"
                placeholder="Titolo"
              />
              <span class="icon is-left">
                <i class="fas fa-mobile-alt"></i>
              </span>
            </p>
          </div>
          <div class="field">
            <textarea
              v-model="newNotification.description"
              v-bind:disabled="waitingNotification"
              class="input textarea"
              type="text"
              maxlength="300"
              placeholder="Descrizione"
            />
          </div>

          <div
            v-if="errorNotification"
            class="has-text-danger has-text-centered mb-3"
          >
            {{ errorNotification }}
          </div>
          <hr />
          <div class="level mt-4">
            <div class="level-right">
              <button
                v-bind:disabled="waitingNotification"
                v-bind:class="{ 'is-loading': waitingNotification }"
                @click.prevent="sendNotification"
                class="button level-item is-success is-small is-rounded"
              >
                <span class="icon is-small">
                  <i class="fas fa-check"></i>
                </span>
                <span>Invia Notifica</span>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TableRowUser from "../components/TableRowUser.vue";
import TableRowBanner from "../components/TableRowBanner.vue";
import TableRowEvent from "../components/TableRowEvent.vue";
import TableRowNews from "../components/TableRowNews.vue";
import TableRowClothing from "../components/TableRowClothing.vue";

import Users from "../services/users";
import Emails from "../services/emails";
import Centers from "../services/events";
import Generals from "../services/generals";
import Photos from "../services/photos";
import Events from "../services/events";
import News from "../services/news";
import Clothing from "../services/clothing";

import firebase from "../firebase";
import { httpsCallable } from "firebase/functions";
import { onSnapshot, query, orderBy } from "firebase/firestore";

export default {
  name: "Management",
  metaInfo: {
    title: "Amministrazione",
  },
  components: {
    TableRowUser,
    TableRowBanner,
    TableRowEvent,
    TableRowNews,
    TableRowClothing,
  },
  data() {
    return {
      newNotification: {
        title: "",
        description: "",
        clickAction: "https://vespaclubmarostica.it/",
      },
      waitingNotification: false,
      errorNotification: "",

      errorNewsletter: null,
      waitingNewsletter: false,

      banners: [],
      loadedBanners: false,
      errorBanner: null,
      waitingBanner: false,

      news: [],
      loadedNews: false,
      errorNews: null,
      waitingNews: false,
      newNews: {
        id: "", // riempito alla creazione
        data: "",
        titolo: "",
        descrizione: "",
        immagine: "",
      },
      errorCreationNews: null,
      waitingCreationNews: false,

      clothings: [],
      loadedClothing: false,
      errorClothing: null,
      waitingClothing: false,
      newClothing: {
        id: "", // riempito alla creazione
        titolo: "",
        immagine: "",
        prezzo: 0,
        available: true,
      },
      errorCreationClothing: null,
      waitingCreationClothing: false,

      events: [],
      loadedEvents: false,
      errorEvents: null,
      waitingEvents: false,
      newEvent: {
        id: "", // riempito alla creazione
        data: "",
        scadenza: "",
        titolo: "",
        descrizione: "",
        immagine: ["", "", "", ""],
        iscritti: [],
        ritrovo: "",
        costoSoci: "",
        costoNonSoci: "",
        pasto: false,
        visibleToNonSoci: false,
      },
      errorCreationEvent: null,
      waitingCreationEvent: false,

      photos: [],
      photosUrls: [],
      loadedPhotos: false,
      errorPhotos: null,
      waitingUploadPhotos: false,
      waitingDeletePhotos: false,
      waitingPhotos: false,

      photosClothing: [],
      photosUrlsClothing: [],
      loadedPhotosClothing: false,
      errorPhotosClothing: null,
      waitingUploadPhotosClothing: false,
      waitingDeletePhotosClothing: false,
      waitingPhotosClothing: false,

      centers: [],
      errorCenters: null,
      waitingCenters: false,

      users: [],
      filterValue: "", // name
      filterList: 0, // option type
      loadedUsers: false,
      errorUsers: null,
      waitingUsers: false,
      waitingRemoveSoci: false,

      unsubscribe: null,
      unsubscribeEvents: null,

      arrPercentages: [], // array di % di caricamento
      arrImages: [], // array di file selezionati
      arrPercentagesClothing: [], // array di % di caricamento abbigliamento
      arrImagesClothing: [], // array di file selezionati abbigliamento
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter((u) => {
        switch (this.filterList) {
          case "0": // tutti
            break;
          case "1": // solo soci
            if (!u.claims.hasAccess) return false;
            break;
          case "2": // sono non soci
            if (u.claims.hasAccess) return false;
            break;
          case "3": // solo soci (no admin)
            if (u.claims.admin || !u.claims.hasAccess) return false;
            break;
          case "4": // solo non soci (no admin)
            if (u.claims.admin || u.claims.hasAccess) return false;
            break;
          case "5": // solo admin
            if (!u.claims.admin) return false;
            break;
        }
        let arr1 = String(u.displayName).toLowerCase().split(" ");
        let arr2 = this.filterValue.trim().toLowerCase().split(" ");
        return (
          arr2.every((v) => arr1.some((z) => z.includes(v))) ||
          u.email.includes(this.filterValue.trim().toLowerCase())
        );
      });
    },
  },
  async created() {
    // centers
    this.waitingCenters = true;
    this.centers = [];
    Centers.getAllRaw()
      .then((snap) => {
        snap.forEach((doc) => {
          let center = doc.data();
          center.id = doc.id;
          this.centers.push(center);
        });
      })
      .catch((reason) => {
        this.errorCenters = reason;
      })
      .finally(() => {
        this.waitingCenters = false;
      });
    // users
    // this.loadUsers();
  },
  methods: {
    // PHOTOS
    async getDownloadUrl(fileName) {
      return await Photos.getDownloadURL(fileName);
    },
    choosePhotos() {
      this.$refs.inputPhoto.click();
    },
    previewImage(event) {
      this.arrImages = event.target.files;
      this.arrPercentages = new Array(this.arrImages.length).fill(0);
    },
    async loadPhotos() {
      // photos
      this.waitingPhotos = true;
      this.photos = [];
      this.photosUrls = [];
      Photos.listAll()
        .then((res) => {
          res.prefixes.forEach(() => {
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
          });
          res.items.forEach((itemRef, index) => {
            // All the items under listRef.
            this.photos.push(itemRef);
            this.photosUrls.push(undefined);
            this.getDownloadUrl(itemRef.fullPath).then((value) => {
              this.photosUrls.splice(index, 1, value);
            });
          });
        })
        .catch((reason) => {
          this.errorPhotos = reason;
        })
        .finally(() => {
          this.waitingPhotos = false;
          this.loadedPhotos = true;
        });
    },
    uploadPhotos() {
      this.errorPhotos = null;
      this.waitingUploadPhotos = true;
      let arrFunctions = [];

      for (let index = 0; index < this.arrImages.length; index++) {
        const img = this.arrImages[index];
        if (!this.photos.find((p) => p.name == img.name)) {
          arrFunctions.push(
            new Promise((res, rej) => {
              const storageRef = Photos.uploadImageBytesResumable(
                img.name,
                img
              );
              storageRef.on(
                "state_changed",
                (snapshot) => {
                  this.arrPercentages.splice(
                    index,
                    1,
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                },
                () => {
                  rej();
                },
                () => {
                  this.arrPercentages[index] = 100;
                  Photos.getDownloadURL(storageRef.snapshot.ref.fullPath).then(
                    (url) => {
                      this.photos.push(Photos.getImage(img.name));
                      this.photosUrls.push(url);
                    }
                  );
                  res();
                }
              );
            })
          );
        }
      }

      Promise.allSettled(arrFunctions).then((results) => {
        this.waitingUploadPhotos = false;

        let erroredPhotos = [];

        for (let index = 0; index < results.length; index++) {
          if (results[index].status == "rejected") {
            erroredPhotos.push(this.arrImages[index]);
            // se ne trova una rejected, ha errori
            if (!this.errorPhotos)
              this.errorPhotos =
                "La richiesta potrebbe non essere stata soddisfatta completamente";
          }
        }

        this.arrImages = erroredPhotos;
        this.arrPercentages = new Array(erroredPhotos.length).fill(0);
      });
    },
    removePhoto(index) {
      this.arrPercentages.splice(index, 1);
      this.arrImages = new Array(...this.arrImages);
      this.arrImages.splice(index, 1);
    },
    async deletePhoto(index) {
      this.waitingDeletePhotos = true;
      Photos.delete(this.photos[index].fullPath)
        .then(() => {
          this.photos.splice(index, 1);
          this.photosUrls.splice(index, 1);
        })
        .finally(() => {
          this.waitingDeletePhotos = false;
        });
    },
    // PHOTOS CLOTHING
    async getDownloadUrlClothing(fileName) {
      return await Photos.getDownloadURLClothing(fileName);
    },
    choosePhotosClothing() {
      this.$refs.inputPhotoClothing.click();
    },
    previewImageClothing(event) {
      this.arrImagesClothing = event.target.files;
      this.arrPercentagesClothing = new Array(
        this.arrImagesClothing.length
      ).fill(0);
    },
    async loadPhotosClothing() {
      // photos
      this.waitingPhotosClothing = true;
      this.photosClothing = [];
      this.photosUrlsClothing = [];
      Photos.listAllClothing()
        .then((res) => {
          res.prefixes.forEach(() => {
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
          });
          res.items.forEach((itemRef, index) => {
            // All the items under listRef.
            this.photosClothing.push(itemRef);
            this.photosUrlsClothing.push(undefined);
            this.getDownloadUrl(itemRef.fullPath).then((value) => {
              this.photosUrlsClothing.splice(index, 1, value);
            });
          });
        })
        .catch((reason) => {
          this.errorPhotosClothing = reason;
        })
        .finally(() => {
          this.waitingPhotosClothing = false;
          this.loadedPhotosClothing = true;
        });
    },
    uploadPhotosClothing() {
      this.errorPhotosClothing = null;
      this.waitingUploadPhotosClothing = true;
      let arrFunctions = [];

      for (let index = 0; index < this.arrImagesClothing.length; index++) {
        const img = this.arrImagesClothing[index];
        if (!this.photosClothing.find((p) => p.name == img.name)) {
          arrFunctions.push(
            new Promise((res, rej) => {
              const storageRef = Photos.uploadImageBytesResumableClothing(
                img.name,
                img
              );
              storageRef.on(
                "state_changed",
                (snapshot) => {
                  this.arrPercentagesClothing.splice(
                    index,
                    1,
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  );
                },
                () => {
                  rej();
                },
                () => {
                  this.arrPercentagesClothing[index] = 100;
                  Photos.getDownloadURLClothing(
                    storageRef.snapshot.ref.fullPath
                  ).then((url) => {
                    this.photosClothing.push(Photos.getImageClothing(img.name));
                    this.photosUrlsClothing.push(url);
                  });
                  res();
                }
              );
            })
          );
        }
      }

      Promise.allSettled(arrFunctions).then((results) => {
        this.waitingUploadPhotosClothing = false;

        let erroredPhotos = [];

        for (let index = 0; index < results.length; index++) {
          if (results[index].status == "rejected") {
            erroredPhotos.push(this.arrImagesClothing[index]);
            // se ne trova una rejected, ha errori
            if (!this.errorPhotosClothing)
              this.errorPhotosClothing =
                "La richiesta potrebbe non essere stata soddisfatta completamente";
          }
        }

        this.arrImagesClothing = erroredPhotos;
        this.arrPercentagesClothing = new Array(erroredPhotos.length).fill(0);
      });
    },
    removePhotoClothing(index) {
      this.arrPercentagesClothing.splice(index, 1);
      this.arrImagesClothing = new Array(...this.arrImagesClothing);
      this.arrImagesClothing.splice(index, 1);
    },
    async deletePhotoClothing(index) {
      this.waitingDeletePhotosClothing = true;
      Photos.deleteClothing(this.photosClothing[index].fullPath)
        .then(() => {
          this.photosClothing.splice(index, 1);
          this.photosUrlsClothing.splice(index, 1);
        })
        .finally(() => {
          this.waitingDeletePhotosClothing = false;
        });
    },
    // BANNERS
    async loadBanners() {
      // banners
      this.waitingBanner = true;
      this.banners = [];
      Generals.getAllRaw()
        .then((snap) => {
          snap.forEach((doc) => {
            let banner = doc.data();
            banner.id = doc.id;
            this.banners.push(banner);
          });
        })
        .catch((reason) => {
          this.errorBanner = reason;
        })
        .finally(() => {
          this.waitingBanner = false;
          this.loadedBanners = true;
        });
    },
    // NEWS
    changeNewsImage(event) {
      this.newNews.immagine = event.target.value;
    },
    async loadNews() {
      // news
      this.waitingNews = true;
      this.news = [];
      let f = async () => {
        // then i load every news
        News.getAllRaw()
          .then((snap) => {
            snap.forEach((doc) => {
              let news = doc.data();
              news.id = doc.id;
              this.news.unshift(news);
            });
            this.clearNewNews();
          })
          .catch((reason) => {
            this.errorNews = reason;
          })
          .finally(() => {
            this.waitingNews = false;
            this.loadedNews = true;
          });
      };
      if (!this.loadedPhotos) {
        // i load every photo reference for the selection
        Photos.listAll()
          .then((res) => {
            res.items.forEach((itemRef) => {
              this.photos.push(itemRef);
            });
            f();
          })
          .catch((reason) => {
            this.waitingNews = false;
            this.errorPhotos = reason;
          })
          .finally(() => {});
      } else {
        f();
      }
    },
    clearNewNews() {
      this.newNews = {
        id: "",
        data: "",
        titolo: "",
        descrizione: "",
        immagine: this.photos.length > 0 ? this.photos[0].fullPath : "",
      };
    },
    async createNewNews() {
      this.waitingCreationNews = true;
      this.errorCreationNews = "";

      News.create(this.newNews)
        .then((v) => {
          this.newNews.id = v.id;
          this.news.unshift(this.newNews);
          this.clearNewNews();
        })
        .catch((error) => {
          this.errorCreationNews = error;
        })
        .finally(() => {
          this.waitingCreationNews = false;
        });
    },
    newsDeleted(news) {
      this.news.splice(
        this.news.findIndex((n) => n.id == news.id),
        1
      );
    },
    // CLOTHING
    changeClothingImage(event) {
      this.newClothing.immagine = event.target.value;
    },
    async loadClothing() {
      // clothing
      this.waitingClothing = true;
      this.clothings = [];
      let f = async () => {
        // then i load every Clothing
        Clothing.getAllRaw()
          .then((snap) => {
            snap.forEach((doc) => {
              let clothing = doc.data();
              clothing.id = doc.id;
              this.clothings.push(clothing);
            });
            this.clearNewClothing();
          })
          .catch((reason) => {
            this.errorClothing = reason;
          })
          .finally(() => {
            this.waitingClothing = false;
            this.loadedClothing = true;
          });
      };
      if (!this.loadedPhotos) {
        // i load every photo reference for the selection
        Photos.listAllClothing()
          .then((res) => {
            res.items.forEach((itemRef) => {
              this.photosClothing.push(itemRef);
            });
            f();
          })
          .catch((reason) => {
            this.waitingClothing = false;
            this.errorPhotosClothing = reason;
          })
          .finally(() => {});
      } else {
        f();
      }
    },
    clearNewClothing() {
      this.newClothing = {
        id: "",
        titolo: "",
        prezzo: 0,
        available: true,
        immagine:
          this.photosClothing.length > 0 ? this.photosClothing[0].fullPath : "",
      };
    },
    async createNewClothing() {
      this.waitingCreationClothing = true;
      this.errorCreationClothing = "";

      Clothing.create(this.newClothing)
        .then((v) => {
          this.newClothing.id = v.id;
          this.clothings.unshift(this.newClothing);
          this.clearNewClothing();
        })
        .catch((error) => {
          this.errorCreationClothing = error;
        })
        .finally(() => {
          this.waitingCreationClothing = false;
        });
    },
    clothingDeleted(clothing) {
      this.clothings.splice(
        this.clothings.findIndex((n) => n.id == clothing.id),
        1
      );
    },
    // EVENTS
    changeEventImage(event, index) {
      this.newEvent.immagine.splice(index, 1, event.target.value);
    },
    async loadEvents() {
      // events
      this.waitingEvents = true;
      this.events = [];
      let f = async () => {
        // then i load every event
        Events.getAllRaw()
          .then((snap) => {
            snap.forEach((doc) => {
              let event = doc.data();
              event.id = doc.id;
              this.events.push(event);
            });
            this.clearNewEvent();

            let q = query(
              firebase.collection("events"),
              orderBy("data", "asc")
            );
            this.unsubscribeEvents = onSnapshot(
              q,
              (querySnapshot) => {
                this.events = [];
                querySnapshot.forEach((doc) => {
                  let event = doc.data();
                  event.id = doc.id;
                  this.events.unshift(event);
                });
              }
              // // onError
              // (error) => (this.errorUsers = error),
              // // onCompletion
              // () => {
              //   this.waitingUsers = false;
              //   this.loadedUsers = true;
              // }
            );
          })
          .catch((reason) => {
            this.errorEvents = reason;
          })
          .finally(() => {
            this.waitingEvents = false;
            this.loadedEvents = true;
          });
      };
      if (!this.loadedPhotos) {
        // i load every photo reference for the selection
        Photos.listAll()
          .then((res) => {
            res.items.forEach((itemRef) => {
              this.photos.push(itemRef);
            });
            f();
          })
          .catch((reason) => {
            this.waitingEvents = false;
            this.errorPhotos = reason;
          })
          .finally(() => {});
      } else {
        f();
      }
    },
    clearNewEvent() {
      this.newEvent = {
        id: "",
        data: "",
        scadenza: "",
        titolo: "",
        descrizione: "",
        immagine: [
          this.photos.length > 0 ? this.photos[0].fullPath : "",
          "",
          "",
          "",
        ],
        iscritti: [],
        ritrovo: "",
        costoSoci: "",
        costoNonSoci: "",
        pasto: false,
        visibleToNonSoci: false,
      };
    },
    async createNewEvent() {
      this.waitingCreationEvent = true;
      this.errorCreationEvents = "";

      await Events.create(this.newEvent)
        .then(() => {
          // questo è gestito con onSnapshot
          // this.newEvent.id = v.id;
          // this.events.push(this.newEvent);
          this.clearNewEvent();
        })
        .catch((error) => {
          this.errorCreationEvents = error;
        })
        .finally(() => {
          this.waitingCreationEvent = false;
        });
    },
    eventDeleted(event) {
      this.events.splice(
        this.events.findIndex((n) => n.id == event.id),
        1
      );
    },
    // OTHER
    changeFilter(event) {
      this.filterList = event.target.value;
    },
    async downloadCsvNewsletter() {
      this.waitingNewsletter = true;
      this.errorNewsletter = "";
      Emails.getAll()
        .then((emails) => {
          let list = [...new Set(emails.map((em) => em.email.trim()))].join(
            ";"
          );
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/csv;charset=utf-8," + encodeURIComponent(list)
          );
          element.setAttribute("download", "subscribed_email_list");

          element.style.display = "none";
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        })
        .catch(() => {
          this.errorNewsletter =
            "Non è stato possibile ottenere la lista di email dal server, riprova";
        })
        .finally(() => {
          this.waitingNewsletter = false;
        });
    },
    async removeAllSocio() {
      if (
        confirm(
          "Sei sicuro di voler rimuovere il 'Socio' a tutti gli utenti attualmente filtrati?\nNon è possibile revocare l'operazione una volta avviata."
        ) == true
      ) {
        this.waitingRemoveSoci = true;
        this.errorUsers = "";

        let setCustomClaims = httpsCallable(
          firebase.functions,
          "setCustomClaims",
          {
            timeout: 15000,
          }
        );

        let arrFunctions = [];

        for (let index = 0; index < this.filteredUsers.length; index++) {
          const user = this.filteredUsers[index];
          arrFunctions.push(
            new Promise((res, rej) => {
              setCustomClaims({
                uid: user.id,
                claims: {
                  hasAccess: false,
                },
              })
                .then((value) => (value ? res() : rej()))
                .catch(() => rej());
            })
          );
        }

        Promise.allSettled(arrFunctions).then((results) => {
          this.waitingRemoveSoci = false;

          if (results.find((v) => v.status == "rejected")) {
            // se ne trova una rejected, ha errori
            this.errorUsers =
              "La richiesta potrebbe non essere stata soddisfatta completamente";
          }
        });
      }
    },
    async loadUsers() {
      // users
      this.waitingUsers = true;
      this.users = [];
      Users.getAllRaw()
        .then(async (snap) => {
          snap.forEach((doc) => {
            let user = doc.data();
            user.id = doc.id;
            this.users.push(user);
          });
          let q = query(firebase.collection("users"));
          this.unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
              this.users = [];
              querySnapshot.forEach((doc) => {
                let user = doc.data();
                user.id = doc.id;
                this.users.push(user);
              });
            }
            // // onError
            // (error) => (this.errorUsers = error),
            // // onCompletion
            // () => {
            //   this.waitingUsers = false;
            //   this.loadedUsers = true;
            // }
          );
        })
        .catch((reason) => {
          this.errorUsers = reason;
        })
        .finally(() => {
          this.waitingUsers = false;
          this.loadedUsers = true;
        });
    },
    // NOTIFICATION
    async sendNotification() {
      this.waitingNotification = true;
      try {
        firebase
          .httpsCallable("sendNotificationToTopic")({
            topic: "general",
            title: this.newNotification.title,
            body: this.newNotification.description,
            clickAction: this.newNotification.clickAction
              ? this.newNotification.clickAction
              : "https://www.vespaclubmarostica.it/",
          })
          .then((res) => {
            if (res.data !== true) {
              console.log(`Error sending notification: `, res.data);
              return false;
            }

            console.log(`Notification sent`);
            return true;
          })
          .catch((error) => {
            console.log(`Error sending notification: `, error);
            this.errorNotification = error;
            return false;
          })
          .finally(() => {
            this.waitingNotification = false;
          });
      } catch (error) {
        console.error(error);
        this.waitingNotification = false;
        this.errorNotification = error;
        return false;
      }
    },
    openModal({ title, description, clickAction }) {
      this.toggleNavbarVisibility(false);

      this.newNotification = {
        title,
        description,
        clickAction,
      };

      document.getElementById("modalNotification").classList.add("is-active");
    },
    closeModal() {
      this.toggleNavbarVisibility(true);
      this.errorNotification = "";

      document
        .getElementById("modalNotification")
        .classList.remove("is-active");
    },
    isModalOpen() {
      return document
        .getElementById("modalNotification")
        .classList.contains("is-active");
    },
    toggleNavbarVisibility(show) {
      let nav = document.getElementById("navbar");
      if (nav) {
        nav.style.visibility = show ? "visible" : "hidden";
      }
    },
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
    if (this.unsubscribeEvents) this.unsubscribeEvents();
  },
};
</script>

<style scoped>
.column-messaggio {
  min-width: 300px;
}

.column-tag {
  min-width: 120px;
}

/* image */
.image {
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.photo:hover .image {
  opacity: 0.3;
}

.photo:hover .middle {
  opacity: 1;
}
</style>
