<template>
  <div class="content-fullheight events">
    <div>
      <div class="title my-5 mx-4">
        Prenota il tuo prossimo evento con
        <p class="has-text-grey-light">Vespa Club Marostica</p>
      </div>

      <div class="column">
        <button v-if="waiting" disabled class="button is-rounded is-info is-loading"></button>
      </div>

      <div v-if="banner != null && banner.enabled" class="hero is-small" v-bind:class="{
        'is-warning': banner.color == 'warning',
        'is-success': banner.color == 'success',
        'is-danger': banner.color == 'danger',
        'is-info': banner.color == 'info',
        'is-dark': banner.color == 'dark',
        'is-light': banner.color == 'light',
      }">
        <div class="hero-body">
          <div class="container has-text-centered">
            <p class="tag mr-2" v-bind:class="{
              'is-dark': banner.color == 'light',
              'is-light': banner.color != 'light',
            }">
              {{ banner.tag }}
            </p>
            {{ banner.message }}
          </div>
        </div>
      </div>

      <hr class="mx-6" />

      <div v-if="events.length == 0 && !waiting">Nessun evento presente</div>
      <div class="is-relative">
        <div v-for="(event, index) in eventsFuture" v-bind:key="'event' + event.id" v-bind:class="{
          'has-background-warning': index == 0,
        }" class="my-3">
          <div class="container pt-6">
            <div v-if="index == 0" class="title is-2 px-2">Prossimi eventi</div>
            <hr v-if="index == 0" class="mx-6 has-background-grey-light" />
            <div class="columns is-vcentered container pb-6">
              <div class="column py-5 mx-4" v-if="event.immagine.some((img) => img)">
                <!-- Start Carousel -->
                <div v-bind:id="'carousel' + event.id" class="
                    cover-image cover-image-block
                    rounded
                    aspect-ratio
                    container
                    slider-container
                  ">
                  <div v-if="!!event.url[0]" class="item-1">
                    <expandable-image close-on-background-click alt="" data-aos="zoom-in" class="
                        image
                        aspect-ratio
                        rounded
                        has-border-light has-shadow-light
                      " v-bind:src="event.url[0]" />
                  </div>
                  <div v-if="!!event.url[1] && $store.getters.hasAccess == true" class="item-2">
                    <expandable-image close-on-background-click alt="" data-aos="zoom-in" class="
                        image
                        aspect-ratio
                        rounded
                        has-border-light has-shadow-light
                      " v-bind:src="event.url[1]" />
                  </div>
                  <div v-if="!!event.url[2] && $store.getters.hasAccess == true" class="item-3">
                    <expandable-image close-on-background-click alt="" data-aos="zoom-in" class="
                        image
                        aspect-ratio
                        rounded
                        has-border-light has-shadow-light
                      " v-bind:src="event.url[2]" />
                  </div>
                  <div v-if="!!event.url[3] && $store.getters.hasAccess == true" class="item-4">
                    <expandable-image close-on-background-click alt="" data-aos="zoom-in" class="
                        image
                        aspect-ratio
                        rounded
                        has-border-light has-shadow-light
                      " v-bind:src="event.url[3]" />
                  </div>
                </div>
                <!-- End Carousel -->
              </div>
              <div class="column mx-6">
                <h1 class="title is-uppercase">
                  {{ event.titolo }}
                </h1>
                <div class="has-text-left mb-4">
                  <!-- NON SOCIO -->
                  <div v-if="
                    $store.getters.hasAccess == false &&
                    !event.visibleToNonSoci
                  " class="
                      notification
                      has-text-centered
                      is-danger
                      rounded
                      is-light
                      mx-5
                    ">
                    Il tuo profilo non è ancora stato aggiunto ai soci.
                    <br />
                    Per abilitare le prenotazioni per gli eventi, contattaci!
                  </div>
                  <!-- SOCIO -->
                  <div v-else>
                    <i class="fa fa-calendar mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">DATA:</strong>
                    {{ getDateString(event.data) }}
                    <br />
                    <i class="fa fa-calendar-times mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">SCAD. ISCRIZIONI:</strong>
                    <span :class="{
                      'has-text-danger': getDateString(event.scadenza) == '-',
                    }">
                      {{
                          getDateString(event.scadenza) == "-"
                            ? "Non abilitate"
                            : getDateString(event.scadenza)
                      }}
                    </span>
                    <br />
                    <i class="fa fa-street-view mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">RITROVO:</strong>
                    {{ event.ritrovo ? event.ritrovo : "-" }}
                    <br />
                    <i class="fa fa-money-bill-alt mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">COSTO:</strong>
                    {{
                        event.costoSoci
                          ? event.costoSoci.toString().includes("€")
                            ? event.costoSoci
                            : event.costoSoci + " €"
                          : "-"
                    }}
                    <span class="has-text-grey">(soci)</span> /
                    {{
                        event.costoNonSoci
                          ? event.costoNonSoci.toString().includes("€")
                            ? event.costoNonSoci
                            : event.costoNonSoci + " €"
                          : "-"
                    }}
                    <span class="has-text-grey">(non soci)</span>
                    <br />
                    <i class="fa fa-scroll mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">DESCRIZIONE:</strong>
                    {{ event.descrizione ? event.descrizione : "-" }}
                  </div>
                  <div v-if="event.error" class="has-text-danger has-text-centered mb-3">
                    {{ event.error }}
                  </div>
                </div>

                <div v-if="$store.getters.hasAccess" class="tag mx-1 is-pulled-left is-clickable" v-bind:class="{
                  'is-dark': index == 0,
                  'is-warning': index != 0,
                }" @click="openModal(index)">
                  {{ countIscritti(event.iscritti) }} iscritti

                  <i class="fa fa-eye ml-2" aria-hidden="true"></i>
                </div>

                <!-- MODAL PARTECIPANTI -->
                <div v-bind:id="'modalEvent' + index" class="modal px-4">
                  <div class="modal-background"></div>
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Lista partecipanti</p>
                      <button class="delete" @click.prevent="closeModal(index)" aria-label="close"></button>
                    </header>
                    <section class="modal-card-body">
                      <div v-if="event.iscritti.length == 0">
                        Nessun iscritto
                      </div>
                      <div v-for="(iscritto, i) in event.iscritti" v-bind:key="event.id + '_iscritto_' + i"
                        class="tag m-1 is-info">
                        {{
                            iscritto.name +
                            (iscritto.quantity > 1
                              ? " (" + iscritto.quantity + ")"
                              : "")
                        }}
                        <span v-if="event.pasto" class="ml-1 has-text-warning">{{
                            iscritto.quantity - iscritto.pasti > 0
                              ? "[ con " +
                              (iscritto.quantity > 1
                                ? iscritto.quantity > iscritto.pasti
                                  ? iscritto.quantity - iscritto.pasti + " "
                                  : ""
                                : "") +
                              "solo giro ]"
                              : ""
                        }}
                        </span>
                      </div>
                    </section>
                    <footer class="modal-card-foot">
                      <button @click.prevent="closeModal(index)" class="button is-fullwidth is-outlined is-dark">
                        Chiudi
                      </button>
                    </footer>
                  </div>
                </div>

                <div v-if="$store.getters.hasAccess" class="mb-6">
                  <div v-if="event.pasto" class="tag mx-1 is-danger is-pulled-left">
                    Pasto
                  </div>
                  <div v-if="event.isSubscribed" class="tag mx-1 is-success is-pulled-left">
                    {{ iscrittoString(event.iscritti) }}
                  </div>
                  <button class="
                      button
                      is-pulled-right
                      dropdown
                      mt-3
                      dropdown-trigger
                      is-right is-hoverable
                    " aria-haspopup="true" v-bind:aria-controls="'dropdown-menu-iscrizione' + index" v-bind:disabled="
                      waitingSubscriptionChange ||
                      !event.scadenza ||
                      new Date().toISOString() > event.scadenza
                    " @click="
  event.isSubscribed
    ? toggleSubscription(event, index)
    : null
" v-bind:class="{
  'is-loading': waitingSubscriptionChange,
  'is-info is-outlined': !event.isSubscribed && index != 0,
  'is-dark is-outlined': !event.isSubscribed && index == 0,
  'is-danger': event.isSubscribed,
}">
                    {{ event.isSubscribed ? "Disiscriviti" : "Iscriviti"
                    }}<span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i>

                      <div class="dropdown-menu" v-bind:id="'dropdown-menu-iscrizione' + index" role="menu">
                        <div class="dropdown-content" v-bind:hidden="
                          event.isSubscribed ||
                          waitingSubscriptionChange ||
                          !event.scadenza ||
                          new Date().toISOString() > event.scadenza
                        ">
                          <div class="dropdown-item">
                            <div class="has-text-centered has-text-grey pb-2">
                              Numero di partecipanti a mio nome
                            </div>
                            <div class="field has-addons has-addons-centered">
                              <p class="control">
                                <button class="button is-outlined is-dark" @click="reduceParticipants(index)">
                                  -
                                </button>
                              </p>
                              <div class="
                                  control
                                  has-border-dark
                                  is-expanded
                                  has-text-centered
                                  px-1
                                ">
                                <div :id="'participantsEvent' + index" class="mt-2 has-text-weight-bold"
                                  placeholder="Nr. Partecipanti">
                                  1
                                </div>
                              </div>
                              <p class="control">
                                <button @click="increaseParticipants(index)" class="button is-outlined is-dark">
                                  +
                                </button>
                              </p>
                            </div>
                            <hr class="my-1" />
                            <div v-if="event.pasto">
                              <div class="has-text-centered has-text-grey pb-2">
                                Numero di pasti
                              </div>
                              <div class="field has-addons has-addons-centered">
                                <p class="control">
                                  <button class="button is-outlined is-dark" @click="reducePasti(index)">
                                    -
                                  </button>
                                </p>
                                <div class="
                                    control
                                    has-border-dark
                                    is-expanded
                                    has-text-centered
                                    px-1
                                  ">
                                  <div :id="'pastiEvent' + index" class="mt-2 has-text-weight-bold"
                                    placeholder="Nr. Pasti">
                                    0
                                  </div>
                                </div>
                                <p class="control">
                                  <button @click="increasePasti(index)" class="button is-outlined is-dark">
                                    +
                                  </button>
                                </p>
                              </div>
                              <hr class="my-1" />
                            </div>
                            <div class="has-text-centered">
                              <button v-bind:disabled="
                                waitingSubscriptionChange ||
                                !event.scadenza ||
                                new Date().toISOString() > event.scadenza
                              " v-bind:class="{
  'is-loading': waitingSubscriptionChange,
  'is-info': !event.isSubscribed,
  'is-danger': event.isSubscribed,
}" @click="toggleSubscription(event, index)" class="
                                  button
                                  mt-2
                                  field
                                  is-fullwidth is-outlined is-small
                                ">
                                {{
                                    event.isSubscribed
                                      ? "Disiscriviti"
                                      : "Iscriviti all'evento"
                                }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr class="mx-6" />
        </div>
        <!-- PAST EVENTS -->
        <div v-for="(event, index) in eventsPast" v-bind:key="'past' + event.id"
          class="has-background-grey-lighter py-3">
          <div class="container pt-6">
            <div v-if="index == 0" class="title is-2 px-2">Eventi Passati</div>
            <hr v-if="index == 0" class="mx-6 has-background-grey-light" />
            <div class="columns is-vcentered container pb-6">
              <div class="column py-5 mx-4">
                <!-- Start Carousel -->
                <div v-bind:id="'carousel' + event.id" class="
                    cover-image cover-image-block
                    rounded
                    aspect-ratio
                    container
                    slider-container
                  ">
                  <div v-if="!!event.url[0]" class="item-1">
                    <expandable-image close-on-background-click alt="" data-aos="zoom-in" class="
                        image
                        aspect-ratio
                        rounded
                        has-border-light has-shadow-light
                      " v-bind:src="event.url[0]" />
                  </div>
                  <div v-if="!!event.url[1] && $store.getters.hasAccess == true" class="item-2">
                    <expandable-image close-on-background-click alt="" data-aos="zoom-in" class="
                        image
                        aspect-ratio
                        rounded
                        has-border-light has-shadow-light
                      " v-bind:src="event.url[1]" />
                  </div>
                  <div v-if="!!event.url[2] && $store.getters.hasAccess == true" class="item-3">
                    <expandable-image close-on-background-click alt="" data-aos="zoom-in" class="
                        image
                        aspect-ratio
                        rounded
                        has-border-light has-shadow-light
                      " v-bind:src="event.url[2]" />
                  </div>
                  <div v-if="!!event.url[3] && $store.getters.hasAccess == true" class="item-4">
                    <expandable-image close-on-background-click alt="" data-aos="zoom-in" class="
                        image
                        aspect-ratio
                        rounded
                        has-border-light has-shadow-light
                      " v-bind:src="event.url[3]" />
                  </div>
                </div>
                <!-- End Carousel -->
              </div>
              <div class="column mx-6">
                <h1 class="title is-uppercase">
                  {{ event.titolo }}
                </h1>
                <div class="has-text-left mb-4">
                  <!-- NON SOCIO -->
                  <div v-if="
                    $store.getters.hasAccess == false &&
                    !event.visibleToNonSoci
                  " class="
                      notification
                      has-text-centered
                      is-danger
                      rounded
                      is-light
                      mx-5
                    ">
                    Il tuo profilo non è ancora stato aggiunto ai soci.
                    <br />
                    Per abilitare le prenotazioni per gli eventi, contattaci!
                  </div>
                  <!-- SOCIO -->
                  <div v-else>
                    <i class="fa fa-calendar mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">DATA:</strong>
                    {{ getDateString(event.data) }}
                    <br />
                    <i class="fa fa-street-view mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">RITROVO:</strong>
                    {{ event.ritrovo ? event.ritrovo : "-" }}
                    <br />
                    <i class="fa fa-money-bill-alt mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">COSTO:</strong>
                    {{
                        event.costoSoci
                          ? event.costoSoci.toString().includes("€")
                            ? event.costoSoci
                            : event.costoSoci + " €"
                          : "-"
                    }}
                    <span class="has-text-grey">(soci)</span> /
                    {{
                        event.costoNonSoci
                          ? event.costoNonSoci.toString().includes("€")
                            ? event.costoNonSoci
                            : event.costoNonSoci + " €"
                          : "-"
                    }}
                    <span class="has-text-grey">(non soci)</span>
                    <br />
                    <i class="fa fa-scroll mr-2" aria-hidden="true"></i>
                    <strong class="is-uppercase">DESCRIZIONE:</strong>
                    {{ event.descrizione ? event.descrizione : "-" }}
                  </div>
                  <div v-if="event.error" class="has-text-danger has-text-centered mb-3">
                    {{ event.error }}
                  </div>
                </div>

                <div v-if="$store.getters.hasAccess" class="tag mx-1 is-pulled-left is-dark">
                  {{ countIscritti(event.iscritti) }} iscritti
                </div>

                <div v-if="$store.getters.hasAccess">
                  <div v-if="event.pasto" class="tag mx-1 is-danger is-pulled-left">
                    Pasto
                  </div>
                  <div v-if="event.isSubscribed" class="tag mx-1 is-success is-pulled-left">
                    Iscritto
                    {{
                        event.iscritti.find(
                          (i) => i.id == $store.getters.user.uid
                        ).quantity > 1
                          ? " (" +
                          event.iscritti.find(
                            (i) => i.id == $store.getters.user.uid
                          ).quantity +
                          ")"
                          : ""
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="has-background-grey-light mx-6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bulmaCarousel from "bulma-extensions/bulma-carousel/dist/js/bulma-carousel.min.js";

import Generals from "../services/generals";
import Events from "../services/events";
import Photos from "../services/photos";

export default {
  name: "Events",
  metaInfo: {
    title: "Eventi",
  },
  data() {
    return {
      banner: {
        enabled: false,
        page: "Events",
        pageName: "Eventi",
        color: "",
        tag: "",
        message: "",
      },

      events: [],
      waitingSubscriptionChange: null,

      waiting: null,
      error: null,
    };
  },
  computed: {
    countIscritti() {
      return (iscritti) =>
        iscritti.reduce((prev, current) => prev + current.quantity, 0);
    },
    eventsPast() {
      return this.events.filter((e) => e.data < new Date().toISOString());
    },
    eventsFuture() {
      return this.events
        .filter((e) => e.data >= new Date().toISOString().substring(0, 10))
        .reverse();
    },
    iscrittoString() {
      return (iscritti) => {
        let pasti = iscritti.find(
          (i) => i.id == this.$store.getters.user.uid
        ).pasti;
        let quantity = iscritti.find(
          (i) => i.id == this.$store.getters.user.uid
        ).quantity;
        return (
          "Iscritto " +
          (quantity > 1 ? "(" + quantity + ")" : "") +
          (quantity - pasti > 0
            ? " con " +
            (quantity > 1
              ? quantity > pasti
                ? quantity - pasti + " "
                : ""
              : "") +
            "solo giro"
            : "")
        );
      };
    },
  },
  async created() {
    // events
    this.waiting = true;

    Events.getAllRaw()
      .then(async (snap) => {
        snap.forEach((doc) => {
          let event = doc.data();
          // utility properties
          event.id = doc.id;
          event.url = ["", "", "", ""];
          event.error = undefined;
          event.isSubscribed =
            event.iscritti.findIndex(
              (e) => e.id == this.$store.getters.user.uid
            ) != -1;
          // rimuovo eventuali immagini vuote..
          event.immagine.splice(
            0,
            event.immagine.length,
            ...event.immagine.filter((img) => img)
          );
          this.events.push(event);
          // async variables
          Promise.allSettled([
            event.immagine[0]
              ? this.getDownloadUrl(event.immagine[0])
                .then((value) => {
                  this.events
                    .find((e) => e.id == event.id)
                    .url.splice(0, 1, value);
                })
                .catch(() => { })
              : null,
            event.immagine[1]
              ? this.getDownloadUrl(event.immagine[1])
                .then((value) => {
                  this.events
                    .find((e) => e.id == event.id)
                    .url.splice(1, 1, value);
                })
                .catch(() => { })
              : null,
            event.immagine[2]
              ? this.getDownloadUrl(event.immagine[2])
                .then((value) => {
                  this.events
                    .find((e) => e.id == event.id)
                    .url.splice(2, 1, value);
                })
                .catch(() => { })
              : null,
            event.immagine[3]
              ? this.getDownloadUrl(event.immagine[3])
                .then((value) => {
                  this.events
                    .find((e) => e.id == event.id)
                    .url.splice(3, 1, value);
                })
                .catch(() => { })
              : null,
          ]).finally(() => {
            if (event.immagine.some((img) => img)) {
              setTimeout(() => {
                // Initialize all elements with carousel class.
                // const carousels = bulmaCarousel.attach(".carousel", options);
                bulmaCarousel.attach("#carousel" + event.id, {
                  duration: 150,
                });

                // To access to bulmaCarousel instance of an element
                // const element = document.querySelector("#my-element");
                // if (element && element.bulmaCarousel) {
                //   // bulmaCarousel instance is available as element.bulmaCarousel
                // }
              }, 200); // TODO... esiste un modo migliore..?
            }
          });
        });
      })
      .catch((reason) => {
        this.error = reason;
      })
      .finally(() => {
        this.waiting = false;
      });
    // banner
    Generals.getBannerEvents()
      .then((v) => {
        this.banner = v;
      })
      .catch(() => { });
  },
  methods: {
    openModal(index) {
      document
        .getElementById(String("modalEvent" + index))
        .classList.add("is-active");
    },
    closeModal(index) {
      document
        .getElementById(String("modalEvent" + index))
        .classList.remove("is-active");
    },
    increaseParticipants(index) {
      document.getElementById(String("participantsEvent" + index)).innerHTML =
        parseInt(
          document.getElementById(String("participantsEvent" + index))
            .innerHTML ?? 0
        ) + 1;
    },
    reduceParticipants(index) {
      let v = this.events[index].pasto
        ? parseInt(
          document.getElementById(String("pastiEvent" + index)).innerHTML ?? 1
        )
        : 0;
      let p = parseInt(
        document.getElementById(String("participantsEvent" + index))
          .innerHTML ?? 1
      );
      if (p > 1) {
        if (p == v) {
          document.getElementById(String("pastiEvent" + index)).innerHTML =
            v - 1;
        }
        document.getElementById(String("participantsEvent" + index)).innerHTML =
          p - 1;
      }
    },
    increasePasti(index) {
      let v = parseInt(
        document.getElementById(String("pastiEvent" + index)).innerHTML ?? 1
      );
      let p = parseInt(
        document.getElementById(String("participantsEvent" + index))
          .innerHTML ?? 1
      );
      if (v < p) {
        document.getElementById(String("pastiEvent" + index)).innerHTML =
          parseInt(
            document.getElementById(String("pastiEvent" + index)).innerHTML ?? 0
          ) + 1;
      }
    },
    reducePasti(index) {
      let v = parseInt(
        document.getElementById(String("pastiEvent" + index)).innerHTML ?? 1
      );
      if (v > 0) {
        document.getElementById(String("pastiEvent" + index)).innerHTML = v - 1;
      }
    },

    async toggleSubscription(event, index) {
      event.error = "";
      this.waitingSubscriptionChange = true;
      if (this.eventsFuture.find((e) => e.id == event.id).isSubscribed) {
        Events.unsubscribe(event.id, {
          id: this.$store.getters.user.uid,
          name: this.$store.getters.username,
          quantity: this.eventsFuture[index].iscritti.find(
            (is) => is.id == this.$store.getters.user.uid
          ).quantity,
          pasti:
            this.eventsFuture[index].iscritti.find(
              (is) => is.id == this.$store.getters.user.uid
            ).pasti ?? 0,
        })
          .then(() => {
            event.iscritti.splice(
              event.iscritti.findIndex(
                (iscr) => iscr.id == this.$store.getters.user.uid
              ),
              1
            );
            event.isSubscribed = false;
          })
          .catch((err) => {
            event.error = err;
          })
          .finally(() => {
            this.waitingSubscriptionChange = false;
          });
      } else {
        let newQuantity = parseInt(
          document.getElementById(String("participantsEvent" + index))
            .innerHTML ?? 1
        );
        let numeroPasti = this.eventsFuture[index].pasto
          ? parseInt(
            document.getElementById(String("pastiEvent" + index)).innerHTML ??
            0
          )
          : 0;
        Events.subscribe(event.id, {
          id: this.$store.getters.user.uid,
          name: this.$store.getters.username,
          quantity: newQuantity,
          pasti: numeroPasti,
        })
          .then(() => {
            event.iscritti.push({
              id: this.$store.getters.user.uid,
              name: this.$store.getters.username,
              quantity: newQuantity,
              pasti: numeroPasti,
            });
            event.isSubscribed = true;
          })
          .catch((err) => {
            event.error = err;
          })
          .finally(() => {
            this.waitingSubscriptionChange = false;
          });
      }
    },
    async getDownloadUrl(fileName) {
      return await Photos.getDownloadURL(fileName);
    },
    getDateString(date) {
      if (date) {
        let d = new Date(date);
        return d.toLocaleDateString("it-IT", {
          year: "numeric",
          weekday: "long",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
      } else return "-";
    },
  },
  mounted() { },
};
</script>

<style>
body>.expandable-image.expanded>img {
  max-height: 85vh !important;
}
</style>