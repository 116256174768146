<template>
  <tr class="table-row-clothing">
    <td>
      <textarea
        v-model="clothing.titolo"
        v-bind:disabled="waiting"
        class="input textarea"
        type="text"
        v-on:input="edited = true"
        maxlength="100"
        placeholder="Titolo"
      />
    </td>
    <td class="control">
      <div class="select is-small is-dark">
        <select
          v-bind:disabled="waiting"
          @change="changeImage($event)"
          v-on:change="edited = true"
          class=""
        >
          <option
            v-for="(image, index) in images"
            v-bind:key="index"
            v-bind:selected="image.fullPath == clothing.immagine"
            v-bind:value="image.fullPath"
          >
            {{ image.name }}
          </option>
        </select>
      </div>
    </td>
    <td>
      <input
        v-model="clothing.prezzo"
        v-bind:disabled="waiting"
        v-on:input="edited = true"
        class="input"
        type="text"
        maxlength="12"
        placeholder="Prezzo"
      />
    </td>
    <td class="has-text-right">
      <div class="is-small is-info">
        <div class="field">
          <input
            v-bind:disabled="waiting"
            v-bind:id="'switchEnabled' + clothing.id"
            type="checkbox"
            v-on:change="edited = true"
            v-bind:name="'switchEnabled' + clothing.id"
            class="switch is-info"
            v-model="clothing.available"
          />
          <label v-bind:for="'switchEnabled' + clothing.id"></label>
        </div>
      </div>
    </td>
    <td>
      <button
        class="button is-info mr-2 my-1"
        v-bind:disabled="waiting || !edited"
        v-bind:class="{
          'is-loading': waiting,
        }"
        @click.prevent="save"
      >
        Salva modifiche
      </button>
      <button
        class="button is-danger mr-2 my-1"
        v-bind:disabled="waiting"
        v-bind:class="{
          'is-loading': waiting,
        }"
        @click.prevent="remove"
      >
        Elimina
      </button>
      <div v-if="error" class="has-text-danger has-text-centered mb-3">
        {{ error }}
      </div>
    </td>
  </tr>
</template>

<script>
// import firebase from "../firebase";
import Clothing from "../services/clothing";

export default {
  name: "TableRowClothing",
  data() {
    return {
      error: null,
      waiting: false,
      edited: false,
    };
  },
  props: {
    clothing: {
      required: true,
      type: Object,
    },
    images: {
      required: true,
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    changeAvailable(event) {
      this.clothing.available = event.target.value;
    },
    changeImage(event) {
      this.clothing.immagine = event.target.value;
    },
    async save() {
      this.waiting = true;
      this.error = "";

      await Clothing.update(this.clothing.id, this.clothing)
        .then(() => {
          this.edited = false;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    async remove() {
      if (
        confirm("Sei sicuro di voler eliminare questo abbigliamento?") == true
      ) {
        this.waiting = true;
        this.error = "";

        await Clothing.delete(this.clothing.id)
          .then(() => {
            this.$emit("clothing-deleted", this.clothing);
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.waiting = false;
          });
      }
    },
  },
};
</script>