<template>
  <tr class="table-row-banner">
    <td>
      {{ banner.pageName }}
    </td>
    <td>
      <textarea
        v-model="banner.tag"
        v-bind:disabled="waiting"
        class="input textarea"
        type="text"
        v-on:input="edited = true"
        maxlength="30"
        placeholder="Tag"
      />
    </td>
    <td>
      <textarea
        v-model="banner.message"
        v-bind:disabled="waiting"
        class="input textarea"
        type="text"
        v-on:input="edited = true"
        maxlength="500"
        placeholder="Messaggio"
      />
    </td>
    <td class="control has-icons-left">
      <div class="select is-small is-info">
        <select
          v-bind:disabled="waiting"
          @change="changeColor($event)"
          v-on:change="edited = true"
          class=""
        >
          <option v-bind:selected="banner.color == 'danger'" value="danger">
            Pericolo
          </option>
          <option v-bind:selected="banner.color == 'warning'" value="warning">
            Attenzione
          </option>
          <option v-bind:selected="banner.color == 'success'" value="success">
            Successo
          </option>
          <option v-bind:selected="banner.color == 'info'" value="info">
            Info
          </option>
          <option v-bind:selected="banner.color == 'light'" value="light">
            Chiaro
          </option>
          <option v-bind:selected="banner.color == 'dark'" value="dark">
            Scuro
          </option>
        </select>
        <span class="icon is-small is-left">
          <div
            class="p-2 rounded"
            v-bind:class="{
              'has-background-warning': banner.color == 'warning',
              'has-background-success': banner.color == 'success',
              'has-background-danger': banner.color == 'danger',
              'has-background-info': banner.color == 'info',
              'has-background-dark': banner.color == 'dark',
              'has-background-light': banner.color == 'light',
            }"
          ></div>
        </span>
      </div>
    </td>
    <td class="has-text-right">
      <div class="is-small is-info">
        <div class="field">
          <input
            v-bind:disabled="waiting"
            v-bind:id="'switchEnabled' + banner.id"
            type="checkbox"
            v-on:change="edited = true"
            v-bind:name="'switchEnabled' + banner.id"
            class="switch is-info"
            v-model="banner.enabled"
          />
          <label v-bind:for="'switchEnabled' + banner.id"></label>
        </div>
      </div>
    </td>
    <td>
      <button
        class="button is-info"
        v-bind:disabled="waiting || !edited"
        v-bind:class="{
          'is-loading': waiting,
        }"
        @click.prevent="save"
      >
        Salva modifiche
      </button>
      <div v-if="error" class="has-text-danger has-text-centered mb-3">
        {{ error }}
      </div>
    </td>
  </tr>
</template>

<script>
// import firebase from "../firebase";
import General from "../services/generals";

export default {
  name: "TableRowBanner",
  data() {
    return {
      error: null,
      waiting: false,
      edited: false,
    };
  },
  props: {
    banner: {
      required: true,
      type: Object,
    },
  },
  methods: {
    changeEnabled(event) {
      this.banner.enabled = event.target.value;
    },
    changeColor(event) {
      this.banner.color = event.target.value;
    },
    async save() {
      this.waiting = true;
      this.error = "";

      await General.update(this.banner.id, this.banner)
        .then(() => {
          this.edited = false;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.waiting = false;
        });
    },
  },
};
</script>