import firebase from "../firebase";
import { doc, getDocs, query, addDoc, setDoc, deleteDoc, orderBy } from "firebase/firestore";

const db = firebase.collection("clothing");

// {
//     titolo,
//     immagine,
//     available,
// }

class ClothingService {
  async getAllRaw() {
    return await getDocs(query(db, orderBy("titolo", "desc")));
  }

  async getAll() {
    const snapshot = await getDocs(query(db, orderBy("titolo", "desc")));
    return snapshot.docs.map(doc => doc.data());
  }

  async create(center) {
    return await addDoc(db, center);
  }

  async update(id, value) {
    return await setDoc(doc(db, id), value, { merge: true });
  }

  async delete(id) {
    return await deleteDoc(doc(db, id));
  }
}

export default new ClothingService();